import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import { reportOverallFetch } from 'redux/actions';

const Dashboard = () => {
  const match = useRouteMatch();

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path={`${match.url}/queue-management`} exact component={lazy(() => import('./QueueManagement'))} />
        <Route path={`${match.url}/branch-management`} exact component={lazy(() => import('./BranchManagement'))} />
        <Route path={`${match.url}/market-conduct`} exact component={lazy(() => import('./MarketConduct'))} />
        <Route path={`${match.url}/sales-tool`} exact component={lazy(() => import('./SalesTool'))} />
        <Route path={`${match.url}/service-quality`} exact component={lazy(() => import('./ServiceQuality'))} />

        <Route component={lazy(() => import('../error/Error404'))} />
      </Switch>
    </Suspense>
  );
};

export default Dashboard;
