import React, { useMemo, useState, useEffect } from 'react';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import GridContainer from '../../@jumbo/components/GridContainer';
import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../@jumbo/utils/IntlMessages';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Chip,
  Grid,
  TableContainer,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from '@material-ui/core';
import { AccessAlarms, Apartment, CheckCircle, Description, Group } from '@material-ui/icons';
import barguage from 'assets/images/report/barguage.png';
import { REPORT_EXECUTIVE_SUMMARY } from '@jumbo/constants/ThemeOptions';
import CmtAvatar from '@coremat/CmtAvatar';
import { Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { reportExecutiveSummaryFetch } from 'redux/actions';
import { getIcon } from 'routes/dashboard/components/Product';
import { MARKET_CONDUCT_HIDE } from 'constants/Systems';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(theme => REPORT_EXECUTIVE_SUMMARY(theme));

const breadcrumbs = [
  { label: <IntlMessages id={'main'} />, link: '/' },
  { label: <IntlMessages id={'executive-summary'} />, isActive: true },
];

let callOnced = false;

const ExecutiveSummary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const reportExecutiveSummary = useSelector(({ report: { reportExecutiveSummary } }) => reportExecutiveSummary);
  const filter = useSelector(({ form: { FilterForm } }) => (FilterForm ? FilterForm.values : null));
  const authUser = useSelector(({ auth: { authUser } }) => authUser);
  const { sec1, sec2, sec3, sec4, filter: filterParams } = reportExecutiveSummary;
  const section = 'ktb_overall';

  useEffect(() => {
    if (!_.isEmpty(reportExecutiveSummary)) {
      setLoader(false);
    } else {
      callOnced = false;
    }
  }, [reportExecutiveSummary]);

  useMemo(() => {
    if (authUser == null) {
      callOnced = false;
    }
    if (filter != null && authUser && authUser.user_id && callOnced == false) {
      callOnced = true;
      dispatch(
        reportExecutiveSummaryFetch({
          y: filter.year,
          q: filter.batch,
          w: filter.week,
          location: filter.branch.location_type,
          branch_id: filter.branch.branch_code,
          area: filter.branch.area_office,
          zone: filter.branch.area_office_region,
          product: filter.product,
          areas: authUser.user_area_office ? authUser.user_area_office.map(row => row.area_office) : [],
          zones: authUser.user_area_office_region ? authUser.user_area_office_region.map(row => row.area_office_region) : [],
          products: authUser.user_product ? authUser.user_product.map(row => row.product_id) : [],
          level: authUser.type,
        }),
      );
    }
  }, [filter, authUser]);

  useEffect(() => {
    if (!_.isEmpty(reportExecutiveSummary)) {
      setLoader(false);
    } else {
      callOnced = false;
    }
  }, [reportExecutiveSummary]);

  return (
    <PageContainer heading={<IntlMessages id="executive-summary" />} breadcrumbs={breadcrumbs}>
      {loader && <PageLoader />}
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              {_.isEmpty(sec1) && filterParams && (
                <Box p={20}>
                  <Typography variant="h4" align="center">
                    <IntlMessages id="errors.data.notfound" />
                  </Typography>
                </Box>
              )}
              {!_.isEmpty(sec1) && !_.isEmpty(sec2) && (
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="h4" align="left">
                          {sec1.title}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item md={3}>
                      <Box pt={5} pb={5} className={classes.menuMain} alignItems="center" alignContent="center">
                        <Box display="flex" mb={4} textAlign={'center'} alignItems="center" justifyContent="center">
                          <CmtAvatar src="/logo.png" style={{ width: '50%', height: '50%' }} alt="" />
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" color="white" fontSize={'4.5vw'}>
                          {sec2.s.ktb_overall.s}%
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          color={sec2.s.ktb_overall.chg >= 0 ? 'green' : 'red'}
                          fontSize={'2.25vw'}>
                          ({sec2.s.ktb_overall.chg >= 0 ? `+${sec2.s.ktb_overall.chg}` : `${sec2.s.ktb_overall.chg}`}%)
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={9}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Box className={classes.hilight}>
                            <Typography variant="h2">{ReactHtmlParser(sec1.monthly_analysis_title)}</Typography>
                            <Typography variant="body1">{ReactHtmlParser(sec1.monthly_analysis)}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            style={{
                              border: '1px solid #E0E0E0',
                              borderRadius: 5,
                            }}>
                            <Box my={4} display={'flex'} justifyContent={'center'}>
                              <Typography variant="h2">{sec2.title}</Typography>
                            </Box>
                            <Grid container>
                              <Grid item md>
                                <Box pt={2} pb={5} className={classes.menuSub} onClick={() => {}}>
                                  <Box display="flex" textAlign={'center'} alignItems="center" justifyContent="center" p={2}>
                                    <AccessAlarms fontSize="large" />
                                  </Box>
                                  <Box
                                    display="flex"
                                    textAlign={'center'}
                                    alignItems="center"
                                    justifyContent="center"
                                    fontSize={12}>
                                    <IntlMessages id="dashboard.queue-management" />
                                  </Box>
                                  <Box display="flex" alignItems="center" justifyContent="center" m={2}>
                                    <Chip
                                      label={`${sec2.s.queue_mgt.s}%`}
                                      color="secondary"
                                      size="medium"
                                      style={{ fontSize: 12 }}
                                    />
                                  </Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    color={sec2.s.queue_mgt.chg >= 0 ? 'green' : 'red'}
                                    fontSize={14}>
                                    ({sec2.s.queue_mgt.chg >= 0 ? `+${sec2.s.queue_mgt.chg}` : `${sec2.s.queue_mgt.chg}`}%)
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item md>
                                <Box pt={2} pb={5} className={classes.menu} onClick={() => {}}>
                                  <Box display="flex" textAlign={'center'} alignItems="center" justifyContent="center" p={2}>
                                    <Apartment fontSize="large" />
                                  </Box>
                                  <Box
                                    display="flex"
                                    textAlign={'center'}
                                    alignItems="center"
                                    justifyContent="center"
                                    fontSize={12}>
                                    <IntlMessages id="dashboard.branch-management" />
                                  </Box>
                                  <Box display="flex" alignItems="center" justifyContent="center" m={2}>
                                    <Chip
                                      label={`${sec2.s.branch_mgt.s}%`}
                                      color="secondary"
                                      size="medium"
                                      style={{ fontSize: 12 }}
                                    />
                                  </Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    color={sec2.s.branch_mgt.chg >= 0 ? 'green' : 'red'}
                                    fontSize={14}>
                                    ({sec2.s.branch_mgt.chg >= 0 ? `+${sec2.s.branch_mgt.chg}` : `${sec2.s.branch_mgt.chg}`}
                                    %)
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item md>
                                <Box pt={2} pb={5} className={classes.menu} onClick={() => {}}>
                                  <Box display="flex" textAlign={'center'} alignItems="center" justifyContent="center" p={2}>
                                    <CheckCircle fontSize="large" />
                                  </Box>
                                  <Box
                                    display="flex"
                                    textAlign={'center'}
                                    alignItems="center"
                                    justifyContent="center"
                                    fontSize={12}>
                                    <IntlMessages id="dashboard.market-conduct" />
                                  </Box>
                                  <Box display="flex" alignItems="center" justifyContent="center" m={2}>
                                    <Chip
                                      label={`${sec2.s.market_conduct.s}%`}
                                      color="secondary"
                                      size="medium"
                                      style={{ fontSize: 12 }}
                                    />
                                  </Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    color={sec2.s.market_conduct.chg >= 0 ? 'green' : 'red'}
                                    fontSize={14}>
                                    (
                                    {sec2.s.market_conduct.chg >= 0
                                      ? `+${sec2.s.market_conduct.chg}`
                                      : `${sec2.s.market_conduct.chg}`}
                                    %)
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item md>
                                <Box pt={2} pb={5} className={classes.menu} onClick={() => {}}>
                                  <Box display="flex" textAlign={'center'} alignItems="center" justifyContent="center" p={2}>
                                    <Description fontSize="large" />
                                  </Box>
                                  <Box
                                    display="flex"
                                    textAlign={'center'}
                                    alignItems="center"
                                    justifyContent="center"
                                    fontSize={12}>
                                    <IntlMessages id="dashboard.sales-tool" />
                                  </Box>
                                  <Box display="flex" alignItems="center" justifyContent="center" m={2}>
                                    <Chip
                                      label={`${sec2.s.sales_tool.s}%`}
                                      color="secondary"
                                      size="medium"
                                      style={{ fontSize: 12 }}
                                    />
                                  </Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    color={sec2.s.sales_tool.chg >= 0 ? 'green' : 'red'}
                                    fontSize={14}>
                                    ({sec2.s.sales_tool.chg >= 0 ? `+${sec2.s.sales_tool.chg}` : `${sec2.s.sales_tool.chg}`}
                                    %)
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item md>
                                <Box pt={2} pb={5} className={classes.menu} onClick={() => {}}>
                                  <Box display="flex" textAlign={'center'} alignItems="center" justifyContent="center" p={2}>
                                    <Group fontSize="large" />
                                  </Box>
                                  <Box
                                    display="flex"
                                    textAlign={'center'}
                                    alignItems="center"
                                    justifyContent="center"
                                    fontSize={12}>
                                    <IntlMessages id="dashboard.service-quality" />
                                  </Box>
                                  <Box display="flex" alignItems="center" justifyContent="center" m={2}>
                                    <Chip
                                      label={`${sec2.s.service_quality.s}%`}
                                      color="secondary"
                                      size="medium"
                                      style={{ fontSize: 12 }}
                                    />
                                  </Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    color={sec2.s.service_quality.chg >= 0 ? 'green' : 'red'}
                                    fontSize={14}>
                                    (
                                    {sec2.s.service_quality.chg >= 0
                                      ? `+${sec2.s.service_quality.chg}`
                                      : `${sec2.s.service_quality.chg}`}
                                    %)
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box mt={5}>
                    <Divider variant="middle" />
                  </Box>
                </Box>
              )}

              {!_.isEmpty(sec3) && sec3[section] && (
                <Box mt={8} mb={8} p={4} border={1} borderColor={'#E0E0E0'} borderRadius={5}>
                  {sec3[section].sec1 && sec3[section].sec2 && (
                    <TableContainer>
                      <Table className={classes.tableProduct}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" style={{ width: 350 }}>
                              <Typography variant="h2" align="center">
                                {ReactHtmlParser(sec3.title.replaceAll('/', '<br>').replace('(%)', '<br>(%)'))}
                              </Typography>
                            </TableCell>
                            <TableCell align="center" colSpan={10}>
                              <Typography variant="h2" align="center">
                                {sec3.title_column}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" style={{ width: 350 }}>
                              <Typography variant="h3" align="center">
                                {sec3.title_row}
                              </Typography>
                            </TableCell>
                            {sec3[section].sec1.table.map((row, i) => {
                              return (
                                <TableCell align="center" key={i} style={{ width: 78 }}>
                                  <Box height={130} className={classes.menu} onClick={() => {}}>
                                    <Box display="flex" justifyContent="center" p={2}>
                                      {getIcon(row.title)}
                                    </Box>
                                    <Box display="flex" justifyContent="center" style={{ height: 45 }} fontSize={12}>
                                      {row.title}
                                    </Box>
                                    <Box display="flex" justifyContent="center" fontSize={16}>
                                      {`${row.s}%`}
                                    </Box>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      color={row.chg >= 0 ? 'green' : 'red'}
                                      fontSize={12}>
                                      {`(${row.chg}%)`}
                                    </Box>
                                  </Box>
                                </TableCell>
                              );
                            })}
                            {sec3[section].sec1.table.length == 1 && (
                              <React.Fragment>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                              </React.Fragment>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sec3[section].sec2.table.map((row, i) => {
                            // TABLE | MAIN
                            return (
                              <TableRow key={i}>
                                <TableCell style={{ width: 350 }}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={7}>
                                      <Typography variant="body2" align="left">
                                        {row.title}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography variant="h4" align="right">
                                        {`${row.s}%`}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Typography
                                        variant="caption"
                                        align="right"
                                        style={{
                                          color: row.chg >= 0 ? 'green' : 'red',
                                        }}>
                                        {`${row.chg >= 0 ? `(+${row.chg}%)` : `(${row.chg}%)`}`}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                {row.table.map((column, j) => {
                                  return (
                                    <TableCell align="center" className={column.c ? column.c : ''} key={j}>
                                      <Box height={25} fontSize={18}>
                                        {authUser &&
                                        MARKET_CONDUCT_HIDE.includes(authUser.type) &&
                                        filterParams.area != 'all' &&
                                        column.s == 100
                                          ? '-'
                                          : column.s}
                                      </Box>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Box>
              )}
              {!_.isEmpty(sec4) && (
                <Box mt={8} mb={8} p={4} border={1} borderColor={'#E0E0E0'} borderRadius={5}>
                  <TableContainer>
                    <Table className={classes.tableProduct}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" style={{ width: 350, height: 50 }}>
                            <Typography variant="h2" align="center">
                              {sec4.title_row}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" colSpan={10}>
                            <Typography variant="h2" align="center">
                              {sec4.title_column}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sec4[section].sec2.table.map((row, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell style={{ width: 350 }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={7}>
                                    <Typography variant="body2" align="left">
                                      {row.title}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Typography variant="h4" align="right">
                                      {`${row.s}%`}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Typography
                                      variant="caption"
                                      align="right"
                                      style={{
                                        color: row.chg >= 0 ? 'green' : 'red',
                                      }}>
                                      {`${row.chg >= 0 ? `(+${row.chg}%)` : `(${row.chg}%)`}`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              {row.table.map((column, j) => {
                                return (
                                  <TableCell
                                    align="center"
                                    style={{ width: 78 }}
                                    className={column.c ? column.c : ''}
                                    key={j}>
                                    <Box height={25} fontSize={18}>
                                      {authUser &&
                                      MARKET_CONDUCT_HIDE.includes(authUser.type) &&
                                      filterParams.area != 'all' &&
                                      column.s == 100
                                        ? '-'
                                        : column.s}
                                    </Box>
                                  </TableCell>
                                );
                              })}
                              {row.table.length == 1 && (
                                <React.Fragment>
                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                </React.Fragment>
                              )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}

              {!_.isEmpty(sec1) && (
                <Box>
                  <Box mt={16} display={'flex'} alignItems={'center'}>
                    {sec1.remark}
                    <Box ml={8}>
                      <img src={barguage} height={50} />
                    </Box>
                  </Box>
                  <Box ml={12}>N/A คือ ไม่มีการให้คะแนนส่วนนี้ใน product นั้น</Box>
                  <Box ml={12} mt={4}>
                    &nbsp;&nbsp; - &nbsp;&nbsp; คือพื้นที่/ผลิตภัณฑ์ที่ยังไม่ได้ทำการเข้าสำรวจ
                  </Box>
                </Box>
              )}
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default ExecutiveSummary;
