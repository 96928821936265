import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userSignOut, userProfileFetch } from '../../../../redux/actions/AuthActions';
import { MenuItem, MenuList, Paper, Popover, Typography } from '@material-ui/core';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { AccountCircle, ExitToApp } from '@material-ui/icons';
import SidebarThemeContext from '../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import IntlMessages from '@jumbo/utils/IntlMessages';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.sidebarTheme.navActiveBgColor,
    padding: '30px 16px 12px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: props => props.sidebarTheme.textActiveColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    color: props => props.sidebarTheme.textActiveColor,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const history = useHistory();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    dispatch(userProfileFetch());
  }, []);

  useEffect(() => {
    if (_.isEmpty(authUser)) {
      history.push('/signin');
    }
  }, [authUser]);

  // Popover
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  // User Profile
  const onUserProfileClick = () => {
    handlePopoverClose();
    history.push('/app/user/profile');
  };

  // Logout
  const onLogoutClick = () => {
    handlePopoverClose();
    dispatch(userSignOut());
  };

  return (
    <div className={classes.root}>
      <div style={{ justifyContent: 'center', display: 'flex' }}>
        <CmtAvatar src="/logo750.png" size={150} alt="" style={{ backgroundColor: 'white', border: '1px solid white' }} />
      </div>
      <div className={classes.userInfo} onClick={handlePopoverOpen}>
        <div
          className="pointer"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}>
          <div className="mr-2">
            {authUser && (authUser.firstname || authUser.lastname) && (
              <Typography className={classes.userTitle} component="h3" variant="h6">
                {`${authUser.firstname ? authUser.firstname : ''} ${authUser.lastname ? authUser.lastname : ''}`}
              </Typography>
            )}
            {authUser && authUser.email && (
              <Typography className={classes.userSubTitle}>
                {`${authUser.email.length > 30 ? `${authUser.email.substring(0, 30) + '...'}` : `${authUser.email}`}`}
              </Typography>
            )}
          </div>
          <ArrowDropDownIcon className={classes.userSubTitle} />
        </div>
      </div>
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: 'right' }}>
          <Paper elevation={8}>
            <MenuList>
              <MenuItem onClick={onUserProfileClick}>
                <AccountCircle />
                <div className="ml-2">
                  <IntlMessages id="user.profile" />
                </div>
              </MenuItem>
              <MenuItem onClick={onLogoutClick}>
                <ExitToApp />
                <div className="ml-2">
                  <IntlMessages id="signout" />
                </div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )}
    </div>
  );
};

export default SidebarHeader;
