import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import _ from 'lodash';
import { reportOverallFetch } from 'redux/actions';

let callOnced = false;

const HeaderTitle = () => {
  const reportOverall = useSelector(({ report: { reportOverall } }) => reportOverall);
  const filter = useSelector(({ form: { FilterForm } }) => (FilterForm ? FilterForm.values : null));
  const authUser = useSelector(({ auth: { authUser } }) => authUser);
  const { sec1 } = reportOverall;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(reportOverall)) {
    } else {
      callOnced = false;
    }
  }, [reportOverall]);

  useMemo(() => {
    if (authUser == null) {
      callOnced = false;
    }
    if (filter != null && authUser && authUser.user_id && callOnced == false) {
      callOnced = true;
      dispatch(
        reportOverallFetch({
          y: filter.year,
          q: filter.batch,
          w: filter.week,
          location: filter.branch.location_type,
          branch_id: filter.branch.branch_code,
          area: filter.branch.area_office,
          zone: filter.branch.area_office_region,
          product: filter.product,
          areas: authUser.user_area_office ? authUser.user_area_office.map(row => row.area_office) : [],
          zones: authUser.user_area_office_region ? authUser.user_area_office_region.map(row => row.area_office_region) : [],
          products: authUser.user_product ? authUser.user_product.map(row => row.product_id) : [],
          level: authUser.type,
        }),
      );
    }
  }, [filter, authUser]);

  return (
    <Box pl={2}>
      {sec1 && (
        <Box>
          <Typography variant="h4" align="left">
            <IntlMessages id="header.title" values={{ date: sec1.last_updated }} />
          </Typography>
          <Typography variant="h5" align="left">
            <IntlMessages
              id="header.subtitle"
              values={{
                amount: sec1.done.toLocaleString(),
                total: sec1.all.toLocaleString(),
                percent: sec1.progress.toLocaleString(),
              }}
            />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default HeaderTitle;
