export const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  {
    languageId: 'thai',
    locale: 'th',
    name: 'Thai',
    icon: 'th',
  },
];

export const flags = {
  en: '/images/flag/icons8-usa.png',
  th: '/images/flag/icons8-thailand.png',
};
