export const DRAWER_BREAK_POINT = {
  SX: 'sx',
  SM: 'sm',
  MD: 'md',
};

export const HEADER_TYPE = {
  FIXED: 'fixed',
  STATIC: 'static',
};

export const SIDEBAR_TYPE = {
  FULL: 'full',
  MINI: 'mini',
  DRAWER: 'drawer',
};
export const Fonts = {
  PRIMARY: 'Kanit',
};

export const THEME_TYPES = {
  DARK: 'dark',
  SEMI_DARK: 'semi-dark',
  LIGHT: 'light',
};

export const LAYOUT_STYLES = {
  BOXED: 'boxed',
  FULL_WIDTH: 'full-width',
  FRAMED: 'framed',
};

export const LAYOUT_TYPES = {
  VERTICAL_DEFAULT: 'vertical-default',
  VERTICAL_MINIMAL: 'vertical-minimal',
  VERTICAL_MINIMAL_NO_HEADER: 'vertical-minimal-no-header',
  VERTICAL_MODERN_SIDEBAR: 'vertical-modern-sidebar',
  HORIZONTAL_DEFAULT: 'horizontal-default',
  HORIZONTAL_DARK: 'horizontal-dark',
  HORIZONTAL_MINIMAL: 'horizontal-minimal',
  HORIZONTAL_TOP_MENU: 'horizontal-top-menu',
};

export const SIDEBAR_WIDTH = {
  SMALL: 280,
  DEFAULT: 304,
  WIDE: 330,
};

export const REPORT_EXECUTIVE_SUMMARY = theme => {
  return {
    hilight: {
      padding: 25,
      backgroundColor: '#F0F0F0',
      borderRadius: 5,
      height: 200,
      overflowY: 'scroll',
    },
    menuSub: {
      height: 175,
    },
    menu: {
      height: 145,
    },
    menuMain: {
      backgroundColor: '#009CD9',
      borderRadius: 5,
      height: 445,
    },
    tableProduct: {
      '& .MuiTableCell-root': {
        border: 'none',
        '&.r': {
          backgroundColor: '#FF0000',
          color: 'white',
        },
        '&.o': {
          backgroundColor: '#FFC000',
        },
        '&.g': {
          backgroundColor: '#92D050',
        },
        '&.y': {
          backgroundColor: '#FFFF00',
        },
      },
    },
  };
};

export const REPORT_DASHBOARD = theme => {
  return {
    menu: {
      cursor: 'pointer',
      height: 145,
    },
    menuActive: {
      cursor: 'pointer',
      backgroundColor: '#F0F0F0',
      borderRadius: 5,
      height: 145,
    },
    formControl: {
      margin: theme.spacing(4),
      minWidth: 120,
    },
    cardPlus: {
      color: 'white',
      backgroundColor: 'green',
      textAlign: 'center',
    },
    cardMinus: {
      color: 'white',
      backgroundColor: 'crimson',
      textAlign: 'center',
    },
    cardRed: {
      color: 'white',
      backgroundColor: '#FF0000',
      textAlign: 'center',
    },
    cardOrange: {
      color: 'black',
      backgroundColor: '#FFBD01',
      textAlign: 'center',
    },
    cardYellow: {
      color: 'black',
      backgroundColor: '#FFFF00',
      textAlign: 'center',
    },
    cardGreen: {
      color: 'white',
      backgroundColor: '#01B242',
      textAlign: 'center',
    },
    cardWhite: {
      color: 'black',
      textAlign: 'center',
    },
    tabTitle: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    tableProduct: {
      '& .MuiTableCell-root': {
        border: 'none',
        '&.r': {
          backgroundColor: '#FF0000',
          color: 'white',
        },
        '&.o': {
          backgroundColor: '#FFC000',
        },
        '&.g': {
          backgroundColor: '#92D050',
        },
        '&.y': {
          backgroundColor: '#FFFF00',
        },
      },
    },
    table: {
      '& .MuiTableRow-head': {
        backgroundColor: 'black',
        '& .MuiTableCell-root': {
          width: '120px',
          color: 'white',
        },
      },
      '& .MuiTableCell-root': {
        border: '1px solid silver',
        '&.border-left': {
          borderLeft: '2px solid black',
        },
        '&.f-col': {
          backgroundColor: '#d9d9d9',
        },
        '&.f-light-col': {
          backgroundColor: '#F2F2F2',
        },
        '&.bg-success': {
          backgroundColor: '#C1F1C8',
        },
        '&.bg-danger': {
          backgroundColor: '#FDD7D7',
        },
      },
      '& .MuiTableRow-root.h-row': {
        backgroundColor: 'gray',

        '& .MuiTableCell-root': {
          color: 'white',
          fontWeight: 'bold',
          '&.success': {
            color: 'green',
          },
          '&.danger': {
            color: 'red',
          },
        },
      },
    },
  };
};

export const REPORT_DOWNLOAD = theme => {
  return {
    smile: {
      '& .r': {
        color: '#FF0000',
      },
      '& .o': {
        color: '#FFBD01',
      },
      '& .y': {
        color: '#FEDC56',
      },
      '& .g': {
        color: '#01B242',
      },
    },
    tableLight: {
      '& .MuiTableRow-head': {
        backgroundColor: 'silver',
        border: '1px solid black',
        '& .MuiTableCell-root': {
          color: 'black',
        },
      },
      '& .MuiTableCell-root': {
        border: 'none',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        '&.q': {
          fontWeight: 'bold',
          fontSize: '13px',
        },
        '&.latest': {
          borderBottom: '1px solid black',
        },
      },
    },
    tableDark: {
      width: '97%',
      margin: '0 0 0 auto',
      '& .MuiTableBody-root': {
        border: '1px solid black',
      },
      '& .MuiTableCell-root': {
        border: 'none',
        '&.q': {
          fontWeight: 'bold',
          fontSize: '13px',
          '&.title-0': {
            color: 'white',
            fontSize: '18px',
          },
          '&.title-1': {
            color: 'white',
            fontSize: '16px',
          },
          '&.title-2': {
            fontSize: '13px',
          },
        },
        '&.a': {
          fontSize: '14px',
          '&.title-0': {
            fontSize: '20px',
          },
          '&.r': {
            color: '#FF0000',
            '&.bg-r': {
              color: 'white',
              backgroundColor: '#FF0000',
            },
          },
          '&.o': {
            color: '#FFBD01',
            '&.bg-o': {
              color: 'black',
              backgroundColor: '#FFBD01',
            },
          },
          '&.g': {
            color: '#01B242',
            '&.bg-g': {
              color: 'white',
              backgroundColor: '#01B242',
            },
          },
          '&.y': {
            color: '#FFFF00',
            '&.bg-y': {
              color: 'black',
              backgroundColor: '#FFFF00',
            },
          },
        },
      },
    },
  };
};

export const getCardColor = (c, classes) => {
  switch (c) {
    case 'r':
      return classes.cardRed;
    case 'o':
      return classes.cardOrange;
    case 'y':
      return classes.cardYellow;
    case 'g':
      return classes.cardGreen;
    default:
      return classes.cardWhite;
  }
};
