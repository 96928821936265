import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { setInitUrl } from 'redux/actions';
import Main from './main';
import User from './user';
import Login from './auth/Login';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import Error404 from './error/Error404';
import Dashboard from './dashboard';
import VideoTutorial from './video-tutorial';
import ExecutiveSummary from './executive-summary';
import Download from './download';
import FWProgress from './fwprogress';
import Demo from './demo';
import Branch from './branch';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const intl = useIntl();
  const location = useLocation();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  useMemo(() => {
    if (initURL === '' && !['/signin', '/signup', '/forgotpassword', '/resetpassword'].includes(location.pathname)) {
      dispatch(setInitUrl(location.pathname));
    }
  }, []);

  if (authUser && authUser.changed_password == 'no') {
    authUser.changed_password = 'yes';
    return <Redirect to={'/app/user/profile/password/change'} />;
  } else {
    if (location.pathname === '' || location.pathname === '/') {
      return <Redirect to={'/app/dashboard/market-conduct'} />;
    } else if (authUser && location.pathname === '/signin') {
      return (
        <Redirect
          to={initURL === '' || initURL === '/' || initURL === '/signin' ? '/app/dashboard/market-conduct' : initURL}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path={`${match.url}app/main`} component={Main} />
        <RestrictedRoute path={`${match.url}app/dashboard`} component={Dashboard} />
        <RestrictedRoute path={`${match.url}app/video-tutorial`} component={VideoTutorial} />
        <RestrictedRoute path={`${match.url}app/executive-summary`} component={ExecutiveSummary} />
        <RestrictedRoute path={`${match.url}app/download`} component={Download} />
        <RestrictedRoute path={`${match.url}app/fwprogress`} component={FWProgress} />
        <RestrictedRoute path={`${match.url}app/user`} component={User} />
        <RestrictedRoute path={`${match.url}app/branch`} component={Branch} />

        <RestrictedRoute path={`${match.url}app/demo`} component={Demo} />

        <Route exact path="/signin" component={Login} />
        <Route exect path="/forgotpassword" component={ForgotPassword} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
