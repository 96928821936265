import React from 'react';
import GridContainer from '../../@jumbo/components/GridContainer';
import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Box, Typography } from '@material-ui/core';

const breadcrumbs = [{ label: <IntlMessages id={'main'} />, isActive: true }];

const Main = () => {
  return (
    <PageContainer heading={<IntlMessages id="main" />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent />
          </CmtCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Main;
