import {
  REPORT_OVERALL_FETCH,
  REPORT_OVERALL_FETCH_RESET,
  REPORT_OVERALL_FETCH_SUCCESS,
  REPORT_FWPROGRESS_FETCH,
  REPORT_FWPROGRESS_FETCH_RESET,
  REPORT_FWPROGRESS_FETCH_SUCCESS,
  REPORT_DOWNLOAD_FETCH,
  REPORT_DOWNLOAD_FETCH_RESET,
  REPORT_DOWNLOAD_FETCH_SUCCESS,
  REPORT_DOWNLOAD_V1_FETCH,
  REPORT_DOWNLOAD_V1_FETCH_RESET,
  REPORT_DOWNLOAD_V1_FETCH_SUCCESS,
  REPORT_DOWNLOAD_DETAIL_FETCH,
  REPORT_DOWNLOAD_DETAIL_FETCH_RESET,
  REPORT_DOWNLOAD_DETAIL_FETCH_SUCCESS,
  REPORT_EXECUTIVE_SUMMARY_FETCH,
  REPORT_EXECUTIVE_SUMMARY_FETCH_RESET,
  REPORT_EXECUTIVE_SUMMARY_FETCH_SUCCESS,
  REPORT_VIDEO_TUTORIAL_FETCH,
  REPORT_VIDEO_TUTORIAL_FETCH_RESET,
  REPORT_VIDEO_TUTORIAL_FETCH_SUCCESS,
} from './types';

export const reportOverallFetch = payload => {
  return {
    type: REPORT_OVERALL_FETCH,
    payload: payload,
  };
};

export const reportOverallFetchSuccess = payload => {
  return {
    type: REPORT_OVERALL_FETCH_SUCCESS,
    payload: payload,
  };
};

export const reportOverallFetchReset = () => {
  return {
    type: REPORT_OVERALL_FETCH_RESET,
  };
};

export const reportFwProgressFetch = payload => {
  return {
    type: REPORT_FWPROGRESS_FETCH,
    payload: payload,
  };
};

export const reportFwProgressFetchSuccess = payload => {
  return {
    type: REPORT_FWPROGRESS_FETCH_SUCCESS,
    payload: payload,
  };
};

export const reportFwProgressFetchReset = () => {
  return {
    type: REPORT_FWPROGRESS_FETCH_RESET,
  };
};

export const reportDownloadFetch = payload => {
  return {
    type: REPORT_DOWNLOAD_FETCH,
    payload: payload,
  };
};

export const reportDownloadFetchSuccess = payload => {
  return {
    type: REPORT_DOWNLOAD_FETCH_SUCCESS,
    payload: payload,
  };
};

export const reportDownloadFetchReset = () => {
  return {
    type: REPORT_DOWNLOAD_FETCH_RESET,
  };
};

export const reportDownloadV1Fetch = payload => {
  return {
    type: REPORT_DOWNLOAD_V1_FETCH,
    payload: payload,
  };
};

export const reportDownloadV1FetchSuccess = payload => {
  return {
    type: REPORT_DOWNLOAD_V1_FETCH_SUCCESS,
    payload: payload,
  };
};

export const reportDownloadV1FetchReset = () => {
  return {
    type: REPORT_DOWNLOAD_V1_FETCH_RESET,
  };
};

export const reportDownloadDetailFetch = payload => {
  return {
    type: REPORT_DOWNLOAD_DETAIL_FETCH,
    payload: payload,
  };
};

export const reportDownloadDetailFetchSuccess = payload => {
  return {
    type: REPORT_DOWNLOAD_DETAIL_FETCH_SUCCESS,
    payload: payload,
  };
};

export const reportDownloadDetailFetchReset = () => {
  return {
    type: REPORT_DOWNLOAD_DETAIL_FETCH_RESET,
  };
};

export const reportExecutiveSummaryFetch = payload => {
  return {
    type: REPORT_EXECUTIVE_SUMMARY_FETCH,
    payload: payload,
  };
};

export const reportExecutiveSummaryFetchSuccess = payload => {
  return {
    type: REPORT_EXECUTIVE_SUMMARY_FETCH_SUCCESS,
    payload: payload,
  };
};

export const reportExecutiveSummaryFetchReset = () => {
  return {
    type: REPORT_EXECUTIVE_SUMMARY_FETCH_RESET,
  };
};

export const reportVideoTutorialFetch = payload => {
  return {
    type: REPORT_VIDEO_TUTORIAL_FETCH,
    payload: payload,
  };
};

export const reportVideoTutorialFetchSuccess = payload => {
  return {
    type: REPORT_VIDEO_TUTORIAL_FETCH_SUCCESS,
    payload: payload,
  };
};

export const reportVideoTutorialFetchReset = () => {
  return {
    type: REPORT_VIDEO_TUTORIAL_FETCH_RESET,
  };
};
