import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { showLoader, userResetPassword } from '../../../redux/actions';
import { renderFields, validateField } from '../../../util/formUtil';
import { resetPasswordFormFields } from '../formFields';
import { NavLink } from 'react-router-dom';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import { DIRECT_CONTACT_EMAIL } from 'constants/Systems';

class ResetPasswordForm extends Component {
  render() {
    const { location, handleSubmit, formValues, reset } = this.props;

    return (
      <form
        onSubmit={handleSubmit(() => {
          let activate_key = '';
          if (!_.isEmpty(location.search)) {
            let params = new URLSearchParams(location.search);
            activate_key = params.get('activate_key');
          }
          this.props.showLoader();
          this.props.userResetPassword({ ...formValues, activate_key });
          reset('ResetPasswordForm');
        })}
        noValidate>
        {renderFields(resetPasswordFormFields)}

        <Box mt={3}>
          <Button type="submit" variant="contained" color="primary" fullWidth={true}>
            <IntlMessages id="commands.submit.resetpassword" />
          </Button>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={5}>
          <NavLink to={`/signin`}>
            <IntlMessages id="go.back.login" />
          </NavLink>
        </Box>
        <Box mt={5} style={{ fontSize: 13 }}>
          หากมีปัญหาการใช้งาน กรุณาติดต่อ <a href={`mailto:${DIRECT_CONTACT_EMAIL}`}>direct contact</a>
        </Box>
      </form>
    );
  }
}

function validate(values) {
  const errors = {};
  resetPasswordFormFields.forEach(({ name, required, type }) => {
    if (!values[name] && required) {
      errors[name] = <IntlMessages id="errors.field.required" />;
      return;
    }
  });
  if (_.isEmpty(errors['password_confirm']) && values['password'] != values['password_confirm']) {
    errors['password_confirm'] = <IntlMessages id="errors.field.password.confirm" />;
  }
  return errors;
}

const mapStateToProps = ({ form }) => {
  const formValues = form.ResetPasswordForm ? form.ResetPasswordForm.values : null;
  return { formValues };
};

ResetPasswordForm = reduxForm({
  validate,
  initialValues: {},
  form: 'ResetPasswordForm',
})(ResetPasswordForm);

export default withRouter(connect(mapStateToProps, { userResetPassword, showLoader })(ResetPasswordForm));
