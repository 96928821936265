import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  HourglassEmptyOutlined,
  MailOutlineOutlined,
  DraftsOutlined,
  EventAvailableOutlined,
  EventBusyOutlined,
  CheckCircleOutlineOutlined,
  AccessAlarm,
} from '@material-ui/icons';
import CmtMediaObject from '../../../../../../@coremat/CmtMediaObject';
import CmtAvatar from '../../../../../../@coremat/CmtAvatar';
import { getDateElements } from '../../../../../utils/dateHelper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Redirect } from 'react-router';

const useStyles = makeStyles(theme => ({
  feedItemRoot: {
    padding: '10px 0',
    position: 'relative',
    borderBottom: `1px solid ${alpha(theme.palette.common.dark, 0.035)}`,
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
    '& .Cmt-media-image': {
      alignSelf: 'flex-start',
      width: 56,
    },
    '& .Cmt-media-body': {
      width: 'calc(100% - 56px)',
      flex: 'inherit',
    },
  },
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: 'pointer',
  },
}));

const icons = {
  A: <MailOutlineOutlined />,
  'A+': <DraftsOutlined />,
  B: <HourglassEmptyOutlined />,
  'B-': <EventBusyOutlined />,
  C: <HourglassEmptyOutlined />,
  'C-': <EventBusyOutlined />,
  D: <EventAvailableOutlined />,
  E: <CheckCircleOutlineOutlined />,
};

const colors = {
  A: 'grey',
  'A+': 'grey',
  B: 'grey',
  'B-': 'red',
  C: 'grey',
  'C-': 'red',
  D: 'green',
  E: 'green',
};

const NotificationItem = ({ item }) => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  const getTitle = (item, classes) => {
    return (
      <Typography component="div" variant="h5" className={classes.titleRoot}>
        <Box component="span">
          <IntlMessages
            id="notification.title"
            values={{
              case_no: item.ticket.case_no,
              status: intl.formatMessage({
                id: `ticket.status.${item.status}`,
              }),
            }}
          />
        </Box>
      </Typography>
    );
  };

  const getSubTitle = (item, classes) => {
    return (
      <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
        <Box fontSize={16} clone>
          <AccessAlarm />
        </Box>
        <Box ml={2}>{moment(item.notified_date).fromNow()}</Box>
      </Box>
    );
  };

  return (
    <Box className={classes.feedItemRoot}>
      <CmtMediaObject
        avatarPos="center"
        avatar={
          <CmtAvatar
            size={40}
            color={colors[item.status]}
            onClick={() => {
              history.push(`/app/redalert/edit/${item.ticket._id}`);
            }}>
            {icons[item.status]}
          </CmtAvatar>
        }
        title={getTitle(item, classes)}
        subTitle={getSubTitle(item, classes)}
        onBodyClick={() => {
          history.push(`/app/redalert/edit/${item.ticket._id}`);
        }}
      />
    </Box>
  );
};

export default NotificationItem;
