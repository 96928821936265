import {
  REPORT_OVERALL_FETCH_SUCCESS,
  REPORT_OVERALL_FETCH_RESET,
  REPORT_FWPROGRESS_FETCH_SUCCESS,
  REPORT_FWPROGRESS_FETCH_RESET,
  REPORT_DOWNLOAD_FETCH_SUCCESS,
  REPORT_DOWNLOAD_FETCH_RESET,
  REPORT_DOWNLOAD_V1_FETCH_SUCCESS,
  REPORT_DOWNLOAD_V1_FETCH_RESET,
  REPORT_DOWNLOAD_DETAIL_FETCH_SUCCESS,
  REPORT_DOWNLOAD_DETAIL_FETCH_RESET,
  REPORT_EXECUTIVE_SUMMARY_FETCH_SUCCESS,
  REPORT_EXECUTIVE_SUMMARY_FETCH_RESET,
  REPORT_VIDEO_TUTORIAL_FETCH_SUCCESS,
  REPORT_VIDEO_TUTORIAL_FETCH_RESET,
} from '../actions/types';

const INIT_STATE = {
  reportOverall: {},
  reportFwProgress: {},
  reportDownload: {},
  reportDownloadV1: {},
  reportDownloadDetail: {},
  reportExecutiveSummary: {},
  reportVideoTutorial: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REPORT_OVERALL_FETCH_SUCCESS:
      return {
        ...state,
        reportOverall: action.payload,
      };
    case REPORT_OVERALL_FETCH_RESET:
      return {
        ...state,
        reportOverall: {},
      };
    case REPORT_FWPROGRESS_FETCH_SUCCESS:
      return {
        ...state,
        reportFwProgress: action.payload,
      };
    case REPORT_FWPROGRESS_FETCH_RESET:
      return {
        ...state,
        reportFwProgress: {},
      };
    case REPORT_DOWNLOAD_DETAIL_FETCH_SUCCESS:
      return {
        ...state,
        reportDownloadDetail: action.payload,
      };
    case REPORT_DOWNLOAD_DETAIL_FETCH_RESET:
      return {
        ...state,
        reportDownloadDetail: {},
      };
    case REPORT_DOWNLOAD_FETCH_SUCCESS:
      return {
        ...state,
        reportDownload: action.payload,
      };
    case REPORT_DOWNLOAD_FETCH_RESET:
      return {
        ...state,
        reportDownload: {},
      };
    case REPORT_DOWNLOAD_V1_FETCH_SUCCESS:
      return {
        ...state,
        reportDownloadV1: action.payload,
      };
    case REPORT_DOWNLOAD_V1_FETCH_RESET:
      return {
        ...state,
        reportDownloadV1: {},
      };
    case REPORT_EXECUTIVE_SUMMARY_FETCH_SUCCESS:
      return {
        ...state,
        reportExecutiveSummary: action.payload,
      };
    case REPORT_EXECUTIVE_SUMMARY_FETCH_RESET:
      return {
        ...state,
        reportExecutiveSummary: {},
      };
    case REPORT_VIDEO_TUTORIAL_FETCH_SUCCESS:
      return {
        ...state,
        reportVideoTutorial: action.payload,
      };
    case REPORT_VIDEO_TUTORIAL_FETCH_RESET:
      return {
        ...state,
        reportVideoTutorial: {},
      };
    default:
      return state;
  }
};
