import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  errorNumber: {
    color: theme.palette.text.primary,
    fontWeight: 800,
    lineHeight: 1,
    marginBottom: 30,
    textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
  },
}));

const Error404 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box fontSize={{ xs: 100, sm: 160 }} className={classes.errorNumber}>
        <IntlMessages id="errors.404.code" />
      </Box>
      <Box fontSize={{ xs: 14, sm: 16 }} mb={4} color="grey.500" className="">
        <IntlMessages id="errors.404.desc" />
      </Box>
      <Box mt={4}>
        <Link to={'/'}>
          <Button variant="contained" color="primary">
            <IntlMessages id="go.back.main" />
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Error404;
