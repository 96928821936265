import { BRANCHS_FETCH_SUCCESS, BRANCHS_FETCH_RESET, BRANCH_FETCH_SUCCESS, BRANCH_FETCH_RESET } from '../actions/types';

const INIT_STATE = {
  branchs: [],
  branch: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BRANCHS_FETCH_SUCCESS: {
      return {
        ...state,
        branch: {},
        branchs: action.payload,
      };
    }
    case BRANCH_FETCH_SUCCESS: {
      return {
        ...state,
        branch: action.payload,
      };
    }
    case BRANCHS_FETCH_RESET: {
      return {
        ...state,
        branchs: [],
      };
    }
    case BRANCH_FETCH_RESET: {
      return {
        ...state,
        branch: {},
      };
    }
    default:
      return state;
  }
};
