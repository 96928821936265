import React, { useEffect } from 'react';
import { Slide, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from '../../../redux/actions/NotifyActions';
import PageLoader from '../PageComponents/PageLoader';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const ContentLoader = () => {
  const { loader, errorMessage, successMessage, infoMessage, warningMessage } = useSelector(({ notify }) => notify);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      {loader && <PageLoader />}
      {
        <Snackbar
          open={Boolean(errorMessage)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={SlideTransition}
          onClose={() => {
            dispatch(hideMessage());
          }}>
          <Alert
            variant="filled"
            severity="error"
            onClose={() => {
              dispatch(hideMessage());
            }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      }
      {
        <Snackbar
          open={Boolean(successMessage)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={SlideTransition}
          onClose={() => {
            dispatch(hideMessage());
          }}>
          <Alert
            variant="filled"
            severity="success"
            onClose={() => {
              dispatch(hideMessage());
            }}>
            {successMessage}
          </Alert>
        </Snackbar>
      }
      {
        <Snackbar
          open={Boolean(infoMessage)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={SlideTransition}
          onClose={() => {
            dispatch(hideMessage());
          }}>
          <Alert
            variant="filled"
            severity="info"
            onClose={() => {
              dispatch(hideMessage());
            }}>
            {infoMessage}
          </Alert>
        </Snackbar>
      }
      {
        <Snackbar
          open={Boolean(warningMessage)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={SlideTransition}
          onClose={() => {
            dispatch(hideMessage());
          }}>
          <Alert
            variant="filled"
            severity="warning"
            onClose={() => {
              dispatch(hideMessage());
            }}>
            {warningMessage}
          </Alert>
        </Snackbar>
      }
    </React.Fragment>
  );
};

export default ContentLoader;
