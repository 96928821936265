//Auth const
export const USER_SIGNIN = 'user_signin';
export const USER_SIGNIN_SUCCESS = 'user_signin_success';
export const USER_SIGNOUT = 'user_signout';
export const USER_SIGNOUT_SUCCESS = 'user_signout_success';
export const USER_PROFILE_FETCH = 'user_profile_fetch';
export const USER_PROFILE_FETCH_SUCCESS = 'user_profile_fetch_success';
export const USER_PROFILE_FETCH_RESET = 'user_profile_fetch_reset';
export const USER_FORGOT_PASSWORD = 'user_forgot_password';
export const USER_FORGOT_PASSWORD_SUCCESS = 'user_forgot_password_success';
export const USER_RESET_PASSWORD = 'user_reset_password';
export const USER_RESET_PASSWORD_SUCCESS = 'user_reset_password_success';
export const INIT_URL = 'init_url';

// User
export const USER_CREATE = 'user_create';
export const USER_UPDATE = 'user_update';
export const USER_DELETE = 'user_delete';
export const USER_FETCH = 'user_fetch';
export const USER_FETCH_SUCCESS = 'user_fetch_success';
export const USER_FETCH_RESET = 'user_fetch_reset';
export const USERS_FETCH = 'users_fetch';
export const USERS_FETCH_SUCCESS = 'users_fetch_success';
export const USERS_FETCH_RESET = 'users_fetch_reset';

// Branch
export const BRANCH_CREATE = 'branch_create';
export const BRANCH_UPDATE = 'branch_update';
export const BRANCH_DELETE = 'branch_delete';
export const BRANCH_FETCH = 'branch_fetch';
export const BRANCH_FETCH_SUCCESS = 'branch_fetch_success';
export const BRANCH_FETCH_RESET = 'branch_fetch_reset';
export const BRANCHS_FETCH = 'branchs_fetch';
export const BRANCHS_FETCH_SUCCESS = 'branchs_fetch_success';
export const BRANCHS_FETCH_RESET = 'branchs_fetch_reset';

// Notification
export const HIDE_MESSAGE = 'hide_message';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const SHOW_INFO_MESSAGE = 'show_info_message';
export const SHOW_SUCCESS_MESSAGE = 'show_success_message';
export const SHOW_WARNING_MESSAGE = 'show_warning_message';
export const SHOW_ERROR_MESSAGE = 'show_error_message';

export const NOTIFICATION_CREATE = 'notification_create';
export const NOTIFICATION_UPDATE = 'notification_update';
export const NOTIFICATION_DELETE = 'notification_delete';
export const NOTIFICATION_FETCH = 'notification_fetch';
export const NOTIFICATION_FETCH_SUCCESS = 'notification_fetch_success';
export const NOTIFICATION_FETCH_RESET = 'notification_fetch_reset';
export const NOTIFICATIONS_FETCH = 'notifications_fetch';
export const NOTIFICATIONS_FETCH_SUCCESS = 'notifications_fetch_success';
export const NOTIFICATIONS_FETCH_RESET = 'notifications_fetch_reset';

// REPORT
export const REPORT_OVERALL_FETCH = 'report_overall_fetch';
export const REPORT_OVERALL_FETCH_RESET = 'report_overall_fetch_reset';
export const REPORT_OVERALL_FETCH_SUCCESS = 'report_overall_fetch_success';
export const REPORT_FWPROGRESS_FETCH = 'report_fwprogress_fetch';
export const REPORT_FWPROGRESS_FETCH_RESET = 'report_fwprogress_fetch_reset';
export const REPORT_FWPROGRESS_FETCH_SUCCESS = 'report_fwprogress_fetch_success';
export const REPORT_EXECUTIVE_SUMMARY_FETCH = 'report_executive_summary_fetch';
export const REPORT_EXECUTIVE_SUMMARY_FETCH_RESET = 'report_executive_summary_fetch_reset';
export const REPORT_EXECUTIVE_SUMMARY_FETCH_SUCCESS = 'report_executive_summary_fetch_success';
export const REPORT_DOWNLOAD_FETCH = 'report_download_fetch';
export const REPORT_DOWNLOAD_FETCH_RESET = 'report_download_fetch_reset';
export const REPORT_DOWNLOAD_FETCH_SUCCESS = 'report_download_fetch_success';
export const REPORT_DOWNLOAD_V1_FETCH = 'report_download_v1_fetch';
export const REPORT_DOWNLOAD_V1_FETCH_RESET = 'report_download_v1_fetch_reset';
export const REPORT_DOWNLOAD_V1_FETCH_SUCCESS = 'report_download_v1_fetch_success';
export const REPORT_DOWNLOAD_DETAIL_FETCH = 'report_download_detail_fetch';
export const REPORT_DOWNLOAD_DETAIL_FETCH_RESET = 'report_download_detail_fetch_reset';
export const REPORT_DOWNLOAD_DETAIL_FETCH_SUCCESS = 'report_download_detail_fetch_success';
export const REPORT_VIDEO_TUTORIAL_FETCH = 'report_video_tutorial_fetch';
export const REPORT_VIDEO_TUTORIAL_FETCH_RESET = 'report_video_tutorial_fetch_reset';
export const REPORT_VIDEO_TUTORIAL_FETCH_SUCCESS = 'report_video_tutorial_fetch_success';

// FILTER
export const SHOW_FILTER = 'show_filter';
export const HIDE_FILTER = 'hide_filter';
export const TOGGLE_FILTER = 'toggle_filter';
export const BRANCHS_FILTER_FETCH = 'branchs_filter_fetch';
export const BRANCHS_FILTER_FETCH_SUCCESS = 'branchs_filter_fetch_success';
export const BRANCHS_FILTER_FETCH_RESET = 'branchs_filter_fetch_reset';
