import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as reduxForm } from 'redux-form';

import Common from './Common';
import AuthReducer from './AuthReducer';
import NotifyReducer from './NotifyReducer';
import FilterReducer from './FilterReducer';
import UserReducer from './UserReducer';
import BranchReducer from './BranchReducer';
import ReportReducer from './ReportReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    notify: NotifyReducer,
    filter: FilterReducer,
    auth: AuthReducer,
    user: UserReducer,
    branch: BranchReducer,
    report: ReportReducer,
    form: reduxForm,
  });
