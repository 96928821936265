import {
  SHOW_FILTER,
  HIDE_FILTER,
  TOGGLE_FILTER,
  BRANCHS_FILTER_FETCH,
  BRANCHS_FILTER_FETCH_SUCCESS,
  BRANCHS_FILTER_FETCH_RESET,
} from '../actions/types';

const INIT_STATE = {
  show: false,
  anchor: 'right',
  branchs: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_FILTER: {
      return {
        ...state,
        show: true,
      };
    }
    case HIDE_FILTER: {
      return {
        ...state,
        show: false,
      };
    }
    case TOGGLE_FILTER: {
      return {
        ...state,
        show: !state.show,
      };
    }
    case BRANCHS_FILTER_FETCH_SUCCESS: {
      return {
        ...state,
        branchs: action.payload,
      };
    }
    case BRANCHS_FILTER_FETCH_RESET: {
      return {
        ...state,
        branchs: [],
      };
    }
    default:
      return state;
  }
};
