import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const Branch = () => {
  const match = useRouteMatch();
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path={`${match.url}`} component={lazy(() => import('./Branch'))} />
        <Route path={`${match.url}/add`} component={lazy(() => import('./BranchEdit'))} />
        <Route path={`${match.url}/edit/:id`} component={lazy(() => import('./BranchEdit'))} />

        <Route component={lazy(() => import('../error/Error404'))} />
      </Switch>
    </Suspense>
  );
};

export default Branch;
