import React, { useEffect } from 'react';
import GridContainer from '../../@jumbo/components/GridContainer';
import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Doughnut, HorizontalBar } from 'react-chartjs-2';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { reportFwProgressFetch } from 'redux/actions';
import _ from 'lodash';

Chart.plugins.register(ChartDataLabels);
const breadcrumbs = [
  { label: <IntlMessages id={'main'} />, link: '/' },
  { label: <IntlMessages id={'fwprogress'} />, isActive: true },
];

let callOnced = false;

const FWProgress = () => {
  const dispatch = useDispatch();
  const reportFwProgress = useSelector(({ report: { reportFwProgress } }) => reportFwProgress);
  const filter = useSelector(({ form: { FilterForm } }) => (FilterForm ? FilterForm.values : null));
  const { sec1, sec2, sec3 } = reportFwProgress;
  console.log(sec1);
  console.log(sec2);
  console.log(sec3);

  useEffect(() => {
    if (!_.isEmpty(reportFwProgress)) {
    } else {
      callOnced = false;
    }
  }, [reportFwProgress]);

  useEffect(() => {
    if (filter != null && callOnced == false) {
      callOnced = true;
      dispatch(
        reportFwProgressFetch({
          y: filter.year,
          q: filter.batch,
          w: filter.week,
        }),
      );
    }
  }, [filter]);

  // Donut Chart [START] ========================================================================
  // KTB
  let labels = { ktb: [], others: [] };
  let datas = { ktb: [], others: [] };
  let backgroundColors = { ktb: [], others: [] };
  let datasets = { ktb: [], others: [] };
  if (sec1) {
    Object.keys(sec1).forEach(key => {
      labels['ktb'].push(sec1[key].title);
      datas['ktb'].push(sec1[key].n);

      switch (key) {
        case 'finish':
          backgroundColors['ktb'].push('#0E9ED5');
          break;
        case 'failed':
          backgroundColors['ktb'].push('#E97132');
          break;
        case 'notfinish':
          backgroundColors['ktb'].push('#bfbfbf');
          break;
        default:
          break;
      }
    });
  }
  datasets['ktb'] = [{ data: datas['ktb'], backgroundColor: backgroundColors['ktb'] }];
  let chart_datas = { labels: labels['ktb'], datasets: datasets['ktb'] };
  let chart_options = {
    legend: false,
    cutoutPercentage: 70,
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return `${data.labels[tooltipItem.index]}`;
        },
      },
      mode: 'index',
    },
    plugins: {
      datalabels: {
        color: '#FFFFFF',
        formatter: (value, ctx) => {
          let sum = ctx.dataset._meta[Object.keys(ctx.dataset._meta)[0]].total;
          let percentage = ((value * 100) / sum).toFixed(0) + '%';
          return value == 0 ? '' : percentage;
        },
        font: {
          weight: 'bold',
          size: 16,
        },
      },
    },
  };
  const plugins = [
    {
      beforeDraw: function(chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = 'bold ' + fontSize + 'em Kanit';
        ctx.fillStyle = 'gray';
        ctx.textBaseline = 'top';
        var text = 'Overall',
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = (height * 0.9) / 2;
        ctx.fillText(text, textX, textY);
        var text = 'KTB',
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = (height * 1.1) / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];
  // Others
  if (sec3) {
    Object.keys(sec3).forEach(key => {
      labels['others'].push(sec3[key].title);
      datas['others'].push(sec3[key].n);

      switch (key) {
        case 'finish':
          backgroundColors['others'].push('#0E9ED5');
          break;
        case 'failed':
          backgroundColors['others'].push('#E97132');
          break;
        case 'notfinish':
          backgroundColors['others'].push('#bfbfbf');
          break;
        default:
          break;
      }
    });
  }
  datasets['others'] = [{ data: datas['others'], backgroundColor: backgroundColors['others'] }];
  let chart_others_datas = {
    labels: labels['others'],
    datasets: datasets['others'],
  };
  let chart_others_options = {
    legend: false,
    cutoutPercentage: 70,
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return `${data.labels[tooltipItem.index]}`;
        },
      },
      mode: 'index',
    },
    plugins: {
      datalabels: {
        color: '#FFFFFF',
        formatter: (value, ctx) => {
          let sum = ctx.dataset._meta[Object.keys(ctx.dataset._meta)[0]].total;
          let percentage = ((value * 100) / sum).toFixed(0) + '%';
          return value == 0 ? '' : percentage;
        },
        font: {
          weight: 'bold',
          size: 16,
        },
      },
    },
  };
  const others_plugins = [
    {
      beforeDraw: function(chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = 'bold ' + fontSize + 'em Kanit';
        ctx.fillStyle = 'gray';
        ctx.textBaseline = 'top';
        var text = 'คู่เทียบ',
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = (height * 0.9) / 2;
        ctx.fillText(text, textX, textY);
        var text = '',
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = (height * 1.1) / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];
  // Donut Chart [END] ========================================================================

  // Bar Chart [START] ========================================================================
  let combine_bar_labels = [];
  let combine_bar_datasets = [];
  if (sec2) {
    let finish = [];
    let finish_ = [];
    let failed = [];
    let failed_ = [];
    let notfinish = [];
    let notfinish_ = [];
    let total = [];

    Object.keys(sec2).forEach(key => {
      combine_bar_labels.push(sec2[key].title);
      let all = sec2[key].data.finish + sec2[key].data.failed + sec2[key].data.notfinish;
      let finish_percent = ((sec2[key].data.finish * 100) / all).toFixed(0);
      let failed_percent = ((sec2[key].data.failed * 100) / all).toFixed(0);
      let notfinish_percent = 100 - finish_percent - failed_percent;
      finish.push(finish_percent);
      failed.push(failed_percent);
      notfinish.push(notfinish_percent);
      finish_.push(sec2[key].data.finish);
      failed_.push(sec2[key].data.failed);
      notfinish_.push(sec2[key].data.notfinish);
      total.push(sec2[key].data.finish + sec2[key].data.failed + sec2[key].data.notfinish);
    });
    combine_bar_datasets = [
      {
        label: 'เข้าสำรวจแล้ว',
        data: finish,
        data_: finish_,
        total: total,
        backgroundColor: '#009cd9',
        pointStyle: 'rect',
      },
      {
        label: 'เข้าสำรวจไม่สำเร็จ',
        data: failed,
        data_: failed_,
        total: total,
        backgroundColor: '#E97132',
        pointStyle: 'rect',
      },
      {
        label: 'ยังไม่ได้เข้าสำรวจ',
        data: notfinish,
        data_: notfinish_,
        total: total,
        backgroundColor: '#bfbfbf',
        pointStyle: 'rect',
      },
    ];
  }

  let combine_bar_datas = {
    labels: combine_bar_labels,
    datasets: combine_bar_datasets,
  };

  let combine_bar_options = {
    responsive: true,
    overlayBars: true,
    maintainAspectRatio: false,
    legend: false,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return `${data.datasets[tooltipItem.datasetIndex].label} : ${data.datasets[tooltipItem.datasetIndex].data_[
            tooltipItem.index
          ].toLocaleString()} visits`;
        },
      },
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        color: '#FFFFFF',
        font: {
          weight: 'bold',
          size: 14,
        },
        formatter: (value, ctx) => {
          return `${value == 0 ? '' : `   ${value}%`}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  };
  // Bar Chart [END] ========================================================================

  return (
    <PageContainer heading={<IntlMessages id="fwprogress" />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent={'center'}>
                    {combine_bar_datasets.map((dataset, i) => {
                      return (
                        <Box key={i} display={'flex'}>
                          <Box mr={2} mt={1.5} width={30} height={10} style={{ backgroundColor: dataset.backgroundColor }} />
                          <Box mr={6}>{dataset.label}</Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Box mb={10}>
                    <Typography variant="h3" align="left">
                      <IntlMessages id="fwprogress.overall" />
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems={'center'}>
                    <Box width={'100%'}>
                      <Doughnut data={chart_datas} options={chart_options} plugins={plugins} />
                      <Box mt={10}>
                        {labels['ktb'].map((label, i) => {
                          return (
                            <Box key={i} display={'flex'} mt={2}>
                              <Box
                                mr={6}
                                color={backgroundColors['ktb'][i] == '#bfbfbf' ? 'black' : backgroundColors['ktb'][i]}>
                                {' '}
                                {label}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box mb={10}>
                    <Typography variant="h3" align="left">
                      <IntlMessages id="fwprogress.area_office_region" />
                    </Typography>
                  </Box>
                  <Box>
                    <HorizontalBar
                      height={400}
                      data={combine_bar_datas}
                      options={combine_bar_options}
                      getElementAtEvent={(elements, event) => {
                        if (event.type === 'click' && elements.length) {
                          const chart = elements[0]._chart;
                          const xLabel = chart.data.labels[elements[0]._index];
                          console.log(elements[0]);
                          console.log(chart.data.labels);
                          console.log(chart.data);
                          console.log(xLabel);
                        }
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={6} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={6}>
                  <Box mb={10}>
                    <Typography variant="h3" align="left">
                      <IntlMessages id="fwprogress.bank" />
                    </Typography>
                  </Box>
                  <Box width={'100%'} mt={5} mb={5}>
                    <Doughnut data={chart_others_datas} options={chart_others_options} plugins={others_plugins} />
                    <Box mt={10}>
                      {labels['others'].map((label, i) => {
                        return (
                          <Box key={i} display={'flex'} mt={2}>
                            <Box
                              mr={6}
                              color={backgroundColors['ktb'][i] == '#bfbfbf' ? 'black' : backgroundColors['ktb'][i]}>
                              {' '}
                              {label}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default FWProgress;
