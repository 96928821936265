import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const match = useRouteMatch();
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {authUser && authUser.type && authUser.type != 'level_1_1' && authUser.type != 'level_2' && (
          <Route path={`${match.url}`} exact component={lazy(() => import('./Download'))} />
        )}
        {authUser && authUser.type && <Route component={lazy(() => import('../error/Error404'))} />}
      </Switch>
    </Suspense>
  );
};

export default Dashboard;
