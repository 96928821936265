import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { toggleFilter, hideFilter } from '../../../../../../redux/actions/FilterActions';
import { branchsFilterFetch } from '../../../../../../redux/actions/FilterActions';
import { Box, IconButton, makeStyles, Tooltip, Drawer } from '@material-ui/core';
import { Tune } from '@material-ui/icons';
import { alpha } from '@material-ui/core/styles';
import clsx from 'clsx';
import FilterForm from '../../../../../../components/form/filter/FilterForm';
import { useLocation } from 'react-router';

const useStyles = makeStyles(theme => ({
  iconRoot: {
    position: 'relative',
    color: alpha(theme.palette.common.white, 0.9),
    '&:hover, &.active': {
      color: theme.palette.common.white,
    },
  },
  paper: {
    width: 375,
  },
}));

const HeaderFilter = () => {
  const classes = useStyles();
  const location = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();
  const filter = useSelector(({ filter }) => filter);
  const authUser = useSelector(({ auth: { authUser } }) => authUser);

  useEffect(() => {
    if (authUser && authUser.user_id) {
      dispatch(branchsFilterFetch({}));
    }
  }, [authUser]);

  return (
    <Box pr={2} style={{ display: location.pathname != '/app/download' ? 'block' : 'none' }}>
      <Tooltip title={`${intl.formatMessage({ id: 'filter' })}`}>
        <IconButton
          onClick={() => {
            dispatch(toggleFilter());
          }}
          className={clsx(classes.iconRoot, 'Cmt-appIcon', {
            active: filter.show,
          })}>
          <Tune />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor={filter.anchor}
        open={filter.show}
        onClose={() => {
          dispatch(hideFilter());
        }}
        classes={{ paper: classes.paper }}
        ModalProps={{ keepMounted: true }}>
        <Box m={5}>
          <FilterForm />
        </Box>
      </Drawer>
    </Box>
  );
};

export default HeaderFilter;
