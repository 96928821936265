import {
  BRANCH_CREATE,
  BRANCH_UPDATE,
  BRANCH_DELETE,
  BRANCH_FETCH,
  BRANCH_FETCH_SUCCESS,
  BRANCHS_FETCH,
  BRANCHS_FETCH_SUCCESS,
  BRANCH_FETCH_RESET,
  BRANCHS_FETCH_RESET,
} from './types';

export const branchFetch = id => {
  return {
    type: BRANCH_FETCH,
    payload: id,
  };
};

export const branchFetchSuccess = payload => {
  return {
    type: BRANCH_FETCH_SUCCESS,
    payload: payload,
  };
};

export const branchFetchReset = () => {
  return {
    type: BRANCH_FETCH_RESET,
  };
};

export const branchsFetch = payload => {
  return {
    type: BRANCHS_FETCH,
    payload: payload,
  };
};

export const branchsFetchSuccess = payload => {
  return {
    type: BRANCHS_FETCH_SUCCESS,
    payload: payload,
  };
};

export const branchsFetchReset = () => {
  return {
    type: BRANCHS_FETCH_RESET,
  };
};

export const branchCreate = values => {
  return {
    type: BRANCH_CREATE,
    payload: values,
  };
};

export const branchUpdate = values => {
  return {
    type: BRANCH_UPDATE,
    payload: values,
  };
};

export const branchDelete = id => {
  return {
    type: BRANCH_DELETE,
    payload: id,
  };
};
