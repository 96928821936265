import { INIT_URL, USER_SIGNIN_SUCCESS, USER_SIGNOUT_SUCCESS, USER_PROFILE_FETCH_SUCCESS } from '../actions/types';

const INIT_STATE = {
  initURL: '',
  authUser: localStorage.getItem('user'),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case USER_PROFILE_FETCH_SUCCESS:
    case USER_SIGNIN_SUCCESS: {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case USER_SIGNOUT_SUCCESS: {
      return {
        ...state,
        authUser: null,
      };
    }
    default:
      return state;
  }
};
