import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { showLoader, userForgotPassword } from '../../../redux/actions';
import { renderFields, validateField } from '../../../util/formUtil';
import { forgotPasswordFormFields } from '../formFields';
import { NavLink } from 'react-router-dom';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { DIRECT_CONTACT_EMAIL } from 'constants/Systems';

class ForgotPasswordForm extends Component {
  render() {
    const { handleSubmit, formValues, reset } = this.props;

    return (
      <form
        onSubmit={handleSubmit(() => {
          this.props.showLoader();
          this.props.userForgotPassword(formValues);
          reset('ForgotPasswordForm');
        })}
        noValidate>
        {renderFields(forgotPasswordFormFields)}
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={3}>
          <Button type="submit" variant="contained" color="primary">
            <IntlMessages id="commands.resetpassword" />
          </Button>
          <NavLink to={`/signin`}>
            <IntlMessages id="signin" />
          </NavLink>
        </Box>
        <Box mt={5} fontSize={14}>
          <IntlMessages id="forgotpassword.title.hint" />
        </Box>
        <Box mt={5} style={{ fontSize: 13 }}>
          หากมีปัญหาการใช้งาน กรุณาติดต่อ <a href={`mailto:${DIRECT_CONTACT_EMAIL}`}>direct contact</a>
        </Box>
      </form>
    );
  }
}

function validate(values) {
  const errors = {};
  forgotPasswordFormFields.forEach(({ name, required, type }) => {
    if (!values[name] && required) {
      errors[name] = <IntlMessages id="errors.field.required" />;
      return;
    }
    if (type === 'email' && !validateField(type, values[name])) {
      errors[name] = <IntlMessages id="errors.field.email" />;
      return;
    }
  });
  return errors;
}

const mapStateToProps = ({ form }) => {
  const formValues = form.ForgotPasswordForm ? form.ForgotPasswordForm.values : null;
  return { formValues };
};

ForgotPasswordForm = reduxForm({
  validate,
  initialValues: {},
  form: 'ForgotPasswordForm',
})(ForgotPasswordForm);

export default withRouter(connect(mapStateToProps, { userForgotPassword, showLoader })(ForgotPasswordForm));
