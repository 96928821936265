export const signInFormFields = [
  {
    label: 'user.username.or.email',
    name: 'email',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 12 },
  },
  {
    label: 'user.password',
    name: 'password',
    type: 'password',
    required: true,
    className: '',
    grid: { xs: 12 },
  },
];

export const forgotPasswordFormFields = [
  {
    label: 'user.email',
    name: 'email',
    type: 'email',
    required: true,
    className: '',
    grid: { xs: 12 },
  },
];

export const resetPasswordFormFields = [
  {
    label: 'user.password',
    name: 'password',
    type: 'password',
    required: true,
    className: '',
    grid: { xs: 12 },
  },
  {
    label: 'user.password_confirm',
    name: 'password_confirm',
    type: 'password',
    required: true,
    className: '',
    grid: { xs: 12 },
  },
];

export const filterFormFields = [
  { label: 'filter.time', type: 'header', className: '', grid: { xs: 12 } },
  {
    label: 'year',
    name: 'year',
    type: 'dropdown',
    required: true,
    className: '',
    grid: { xs: 6 },
    options: [
      { display: 'year.2025', value: '2025' },
      { display: 'year.2024', value: '2024' },
    ],
  },
  {
    label: 'batch',
    name: 'batch',
    type: 'dropdown',
    required: true,
    className: '',
    grid: { xs: 6 },
    options: [
      { display: 'filter.batch.1', value: '1' },
      { display: 'filter.batch.2', value: '2' },
    ],
  },
  {
    label: 'week',
    name: 'week',
    type: 'dropdown',
    required: true,
    className: '',
    grid: { xs: 12 },
    default: { display: 'filter.week.all', value: 'all' },
    options: [
      { display: 'filter.week.1', value: '1' },
      { display: 'filter.week.2', value: '2' },
      { display: 'filter.week.3', value: '3' },
      { display: 'filter.week.4', value: '4' },
      { display: 'filter.week.5', value: '5' },
      { display: 'filter.week.6', value: '6' },
      { display: 'filter.week.7', value: '7' },
      { display: 'filter.week.8', value: '8' },
      { display: 'filter.week.9', value: '9' },
      { display: 'filter.week.10', value: '10' },
      { display: 'filter.week.11', value: '11' },
      { display: 'filter.week.12', value: '12' },
      { display: 'filter.week.13', value: '13' },
      { display: 'filter.week.14', value: '14' },
      { display: 'filter.week.15', value: '15' },
    ],
  },
  { label: 'filter.branch', type: 'header', className: '', grid: { xs: 12 } },
  {
    label: 'filter.branch.area_office_region',
    name: 'branch.area_office_region',
    type: 'autocomplete',
    required: false,
    className: '',
    grid: { xs: 12 },
    default: { display: 'filter.branch.area_office_region.all', value: 'all' },
    options: [],
  },
  {
    label: 'filter.branch.area_office',
    name: 'branch.area_office',
    type: 'autocomplete',
    required: false,
    className: '',
    grid: { xs: 12 },
    default: { display: 'filter.branch.area_office.all', value: 'all' },
    options: [],
  },
  {
    label: 'filter.branch.location_type',
    name: 'branch.location_type',
    type: 'dropdown',
    required: false,
    className: '',
    grid: { xs: 12 },
    default: { display: 'filter.branch.location_type.all', value: 'all' },
    options: [],
  },
  {
    label: 'filter.branch.branch_name',
    name: 'branch.branch_code',
    type: 'autocomplete',
    required: false,
    className: '',
    grid: { xs: 12 },
    default: { display: 'filter.branch.branch_name.all', value: 'all' },
    options: [],
  },
  { label: 'filter.product', type: 'header', className: '', grid: { xs: 12 } },
  {
    label: 'product',
    name: 'product',
    type: 'dropdown',
    required: true,
    className: '',
    grid: { xs: 12 },
    default: { display: 'filter.product.all', value: 'all' },
    options: [
      { display: 'filter.product.1', value: '1' },
      { display: 'filter.product.2', value: '2' },
      { display: 'filter.product.3', value: '3' },
      { display: 'filter.product.4', value: '4' },
      { display: 'filter.product.5', value: '5' },
      { display: 'filter.product.6', value: '6' },
      { display: 'filter.product.7', value: '7' },
      { display: 'filter.product.8', value: '8' },
      { display: 'filter.product.9', value: '9' },
    ],
  },
];

export const filterBarFormFields = [
  {
    label: 'filter.branch.area_office_region',
    name: 'branch.region_id',
    type: 'autocomplete',
    required: false,
    className: '',
    grid: { xs: 4 },
    default: {
      display: 'filter.branch.area_office_region.overall',
      value: 'all',
    },
    options: [],
  },
  {
    label: 'filter.branch.area_office',
    name: 'branch.area_id',
    type: 'autocomplete',
    required: false,
    className: '',
    grid: { xs: 4 },
    default: { display: 'filter.branch.area_office.all', value: 'all' },
    options: [],
  },
  {
    label: 'filter.branch.branch_name',
    name: 'branch.branch_code',
    type: 'autocomplete',
    required: false,
    className: '',
    grid: { xs: 4 },
    default: { display: 'filter.branch.branch_name.all', value: 'all' },
    options: [],
  },
  { grid: { xs: 12 } },
  {
    label: 'filter.compare',
    name: 'compare',
    type: 'dropdown',
    required: false,
    className: '',
    grid: { xs: 4 },
    default: { display: 'filter.compare.time', value: 'time' },
    options: [{ display: 'filter.compare.brand', value: 'brand' }],
  },
];

export const downloadFormFields = [
  {
    label: 'year',
    name: 'year',
    type: 'dropdown',
    required: true,
    className: '',
    grid: { xs: 4 },
    options: [
      { display: 'year.2025', value: '2025' },
      { display: 'year.2024', value: '2024' },
      { display: 'year.2023', value: '2023' },
      { display: 'year.2022', value: '2022' },
      { display: 'year.2020', value: '2020' },
    ],
  },
  {
    label: 'batch',
    name: 'batch',
    type: 'dropdown',
    required: true,
    className: '',
    grid: { xs: 4 },
    options: [
      { display: 'filter.batch.1', value: '1' },
      { display: 'filter.batch.2', value: '2' },
    ],
  },
  {
    label: 'week',
    name: 'week',
    type: 'dropdown',
    required: true,
    className: '',
    grid: { xs: 4 },
    default: { display: 'filter.week.all', value: 'all' },
    options: [
      { display: 'filter.week.1', value: '1' },
      { display: 'filter.week.2', value: '2' },
      { display: 'filter.week.3', value: '3' },
      { display: 'filter.week.4', value: '4' },
      { display: 'filter.week.5', value: '5' },
      { display: 'filter.week.6', value: '6' },
      { display: 'filter.week.7', value: '7' },
      { display: 'filter.week.8', value: '8' },
      { display: 'filter.week.9', value: '9' },
      { display: 'filter.week.10', value: '10' },
      { display: 'filter.week.11', value: '11' },
      { display: 'filter.week.12', value: '12' },
      { display: 'filter.week.13', value: '13' },
      { display: 'filter.week.14', value: '14' },
      { display: 'filter.week.15', value: '15' },
    ],
  },
  {
    label: 'filter.branch.area_office_region',
    name: 'branch.area_office_region',
    type: 'autocomplete',
    required: false,
    className: '',
    grid: { xs: 3 },
    default: { display: 'filter.branch.area_office_region.all', value: 'all' },
    options: [],
  },
  {
    label: 'filter.branch.area_office',
    name: 'branch.area_office',
    type: 'autocomplete',
    required: false,
    className: '',
    grid: { xs: 3 },
    default: { display: 'filter.branch.area_office.all', value: 'all' },
    options: [],
  },
  {
    label: 'filter.branch.location_type',
    name: 'branch.location_type',
    type: 'dropdown',
    required: false,
    className: '',
    grid: { xs: 3 },
    default: { display: 'filter.branch.location_type.all', value: 'all' },
    options: [],
  },
  {
    label: 'filter.branch.branch_name',
    name: 'branch.branch_code',
    type: 'autocomplete',
    required: false,
    className: '',
    grid: { xs: 3 },
    default: { display: 'filter.branch.branch_name.all', value: 'all' },
    options: [],
  },
  {
    label: 'product',
    name: 'product',
    type: 'dropdown',
    required: true,
    className: '',
    grid: { xs: 12 },
    default: { display: 'filter.product.all', value: 'all' },
    options: [
      { display: 'filter.product.99', value: '99' },
      { display: 'filter.product.1', value: '1' },
      { display: 'filter.product.2', value: '2' },
      { display: 'filter.product.3.1', value: '3' },
      { display: 'filter.product.3', value: '3' },
      { display: 'filter.product.10', value: '10' },
      { display: 'filter.product.4', value: '4' },
      { display: 'filter.product.5', value: '5' },
      { display: 'filter.product.6', value: '6' },
      { display: 'filter.product.7', value: '7' },
      { display: 'filter.product.8', value: '8' },
      { display: 'filter.product.9', value: '9' },
    ],
  },
  {
    label: 'bank',
    name: 'bank',
    type: 'dropdown',
    required: true,
    className: '',
    grid: { xs: 12 },
    default: { display: 'filter.bank.ktb', value: 'ktb' },
    options: [{ display: 'filter.bank.others', value: 'others' }],
  },
];

export const userSearchFormFields = [
  {
    label: 'user.username',
    name: 'username',
    type: 'text',
    required: false,
    className: '',
    grid: { xs: 6, md: 3 },
  },
  {
    label: 'user.firstname',
    name: 'firstname',
    type: 'text',
    required: false,
    className: '',
    grid: { xs: 6, md: 3 },
  },
  {
    label: 'user.lastname',
    name: 'lastname',
    type: 'text',
    required: false,
    className: '',
    grid: { xs: 6, md: 3 },
  },
  {
    label: 'user.email',
    name: 'email',
    type: 'text',
    required: false,
    className: '',
    grid: { xs: 6, md: 3 },
  },
  {
    label: 'user.status',
    name: 'status',
    required: false,
    type: 'dropdown',
    className: '',
    options: [
      { display: 'user.status.inactive', value: 'inactive' },
      { display: 'user.status.pending', value: 'pending' },
      { display: 'user.status.active', value: 'active' },
    ],
    grid: { xs: 6, md: 6 },
  },
  {
    label: 'user.type',
    name: 'type',
    type: 'dropdown',
    required: false,
    className: '',
    grid: { xs: 6, md: 6 },
    options: [{ display: 'user.type.root', value: 'root' }],
  },
];

export const userProfileFormFields = [
  {
    label: 'user.username',
    name: 'username',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'user.firstname',
    name: 'firstname',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'user.lastname',
    name: 'lastname',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'user.email',
    name: 'email',
    type: 'email',
    required: true,
    grid: { xs: 4 },
  },
  {
    label: 'user.phone',
    name: 'phone',
    type: 'text',
    required: false,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'user.position_title',
    name: 'position_title',
    type: 'text',
    required: false,
    className: '',
    grid: { xs: 4 },
  },
];

export const userProfilePasswordFormFields = [
  {
    label: 'user.password',
    name: 'password',
    type: 'password',
    required: true,
    className: '',
    grid: { xs: 12 },
  },
  {
    label: 'user.password_confirm',
    name: 'password_confirm',
    type: 'password',
    required: true,
    className: '',
    grid: { xs: 12 },
  },
];

export const userFormFields = [
  {
    label: 'user.username',
    name: 'username',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'user.firstname',
    name: 'firstname',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'user.lastname',
    name: 'lastname',
    type: 'text',
    required: false,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'user.email',
    name: 'email',
    type: 'email',
    required: false,
    grid: { xs: 6 },
  },
  {
    label: 'user.password',
    name: 'password',
    type: 'password',
    required: true,
    className: '',
    grid: { xs: 6 },
  },
  {
    label: 'user.phone',
    name: 'phone',
    type: 'text',
    required: false,
    className: '',
    grid: { xs: 3 },
  },
  {
    label: 'user.position_title',
    name: 'position_title',
    type: 'text',
    required: false,
    className: '',
    grid: { xs: 3 },
  },
  {
    label: 'user.status',
    name: 'status',
    type: 'dropdown',
    required: true,
    grid: { xs: 3 },
    options: [
      { display: 'user.status.inactive', value: 'inactive' },
      { display: 'user.status.pending', value: 'pending' },
      { display: 'user.status.active', value: 'active' },
    ],
  },
  {
    label: 'user.type',
    name: 'type',
    type: 'dropdown',
    required: true,
    className: '',
    grid: { xs: 3 },
    options: [
      { display: 'user.type.root', value: 'root' },
      { display: 'user.type.admin', value: 'admin' },
      { display: 'user.type.leader', value: 'leader' },
      { display: 'user.type.general_manager', value: 'general_manager' },
      { display: 'user.type.area_manager', value: 'area_manager' },
      { display: 'user.type.dealer', value: 'dealer' },
    ],
  },
];

export const branchSearchFormFields = [
  {
    label: 'filter.branch.region',
    name: 'region',
    type: 'dropdown',
    required: false,
    className: '',
    grid: { xs: 6, md: 4 },
    default: { display: 'filter.branch.region.all', value: 'all' },
    options: [],
  },
  {
    label: 'filter.branch.area_office',
    name: 'area_office',
    type: 'dropdown',
    required: false,
    className: '',
    grid: { xs: 6, md: 4 },
    default: { display: 'filter.branch.area_office.all', value: 'all' },
    options: [],
  },
  {
    label: 'filter.branch.area_office_region',
    name: 'area_office_region',
    type: 'dropdown',
    required: false,
    className: '',
    grid: { xs: 6, md: 4 },
    default: { display: 'filter.branch.area_office_region.all', value: 'all' },
    options: [],
  },
  {
    label: 'filter.branch.location_type',
    name: 'location_type',
    type: 'dropdown',
    required: false,
    className: '',
    grid: { xs: 6, md: 6 },
    default: { display: 'filter.branch.location_type.all', value: 'all' },
    options: [],
  },
  {
    label: 'filter.branch.province',
    name: 'province',
    type: 'dropdown',
    required: false,
    className: '',
    grid: { xs: 12, md: 6 },
    default: { display: 'filter.branch.province.all', value: 'all' },
    options: [],
  },
];

export const branchFormFields = [
  {
    label: 'branch.branch_code',
    name: 'branch_code',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'branch.region',
    name: 'region',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'branch.area_office',
    name: 'area_office',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'branch.area_office_region',
    name: 'area_office_region',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'branch.location_type',
    name: 'location_type',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'branch.province',
    name: 'province',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 4 },
  },
  {
    label: 'branch.branch_name',
    name: 'branch_name_th',
    type: 'text',
    required: true,
    className: '',
    grid: { xs: 4 },
  },
];

export const commentFormFields = [
  {
    label: 'leave.comment',
    name: 'message',
    type: 'textarea',
    required: true,
    className: '',
    grid: { xs: 12 },
  },
];
