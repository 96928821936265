import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../@jumbo/components/GridContainer';
import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { reportVideoTutorialFetch } from 'redux/actions';
import { Box, Tooltip, Typography, makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { VideocamSharp, ExpandMore, Description } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import _ from 'lodash';

import { Accordion as AccordionMain, AccordionAccordion } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

const useStyles = makeStyles(theme => ({
  video: {
    cursor: 'pointer',
  },
}));

const breadcrumbs = [
  { label: <IntlMessages id={'main'} />, link: '/' },
  { label: <IntlMessages id={'video-tutorial'} />, isActive: true },
];

const VideoTutorial = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { reportVideoTutorial } = useSelector(({ report }) => report);

  const getIcon = icon => {
    switch (icon) {
      case 'vdo':
        return <VideocamSharp />;
      case 'document':
        return <Description />;
      default:
        return '';
    }
  };

  useEffect(() => {
    dispatch(reportVideoTutorialFetch({}));
  }, []);

  return (
    <PageContainer heading={<IntlMessages id="video-tutorial" />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <CmtCard>
            <CmtCardContent style={{ minHeight: 500 }}>
              {reportVideoTutorial &&
                reportVideoTutorial.video &&
                reportVideoTutorial.video.map((item, i) => {
                  let rootPanels = [];

                  if (item.folder && !_.isEmpty(item.folder.subfolder)) {
                    item.folder.subfolder.forEach((folder, j) => {
                      // level 1 | วิดีโอการบรรยาย, เอกสารประกอบการบรรยาย
                      let rootPanel = { key: j, title: folder.title };
                      let panels = [];

                      if (folder.content && !_.isEmpty(folder.content)) {
                        // level 2 | คลิปวีดีโอ
                        let component;
                        folder.content.forEach((content, k) => {
                          let files;
                          if (content.file && !_.isEmpty(content.file)) {
                            // level 3

                            files = content.file.map((file, z) => {
                              return (
                                <Box display={'flex'} mb={3} ml={5} alignItems={'center'}>
                                  <Box>{getIcon(file.icon)}</Box>
                                  <Box ml={4}>
                                    <a href={file.file} target="_blank" rel="noreferrer">
                                      {' '}
                                      {file.title}
                                    </a>
                                  </Box>
                                </Box>
                              );
                            });
                          }
                          panels.push({
                            key: k,
                            title: content.title,
                            content: files,
                          });

                          component = (
                            <div>
                              <AccordionAccordion panels={panels} />
                            </div>
                          );
                        });
                        rootPanel['content'] = { content: component };
                      }
                      rootPanels.push(rootPanel);
                    });
                  }

                  return (
                    <Accordion key={i}>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Box ml={5}>
                          <Typography variant="h2">{item.title}</Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails className="ml-5 mr-5 mb-5">
                        <AccordionMain panels={rootPanels} styled style={{ width: '100%' }} />
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </CmtCardContent>
          </CmtCard>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default VideoTutorial;
