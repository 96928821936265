import {
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_ERROR_MESSAGE,
  SHOW_WARNING_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  SHOW_INFO_MESSAGE,
} from './types';

// SHOW
export const showErrorMessage = message => {
  return {
    type: SHOW_ERROR_MESSAGE,
    payload: message,
  };
};

export const showWarningMessage = message => {
  return {
    type: SHOW_WARNING_MESSAGE,
    payload: message,
  };
};

export const showSuccessMessage = message => {
  return {
    type: SHOW_SUCCESS_MESSAGE,
    payload: message,
  };
};

export const showInfoMessage = message => {
  return {
    type: SHOW_INFO_MESSAGE,
    payload: message,
  };
};

export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

// HIDE
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
