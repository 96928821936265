import {
  INIT_URL,
  USER_SIGNIN,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_SIGNOUT_SUCCESS,
  USER_PROFILE_FETCH,
  USER_PROFILE_FETCH_SUCCESS,
  USER_FORGOT_PASSWORD,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD,
  USER_RESET_PASSWORD_SUCCESS,
} from './types';

export const userSignIn = payload => {
  return {
    type: USER_SIGNIN,
    payload: payload,
  };
};

export const userSignInSuccess = authUser => {
  return {
    type: USER_SIGNIN_SUCCESS,
    payload: authUser,
  };
};

export const userSignOut = () => {
  return {
    type: USER_SIGNOUT,
  };
};

export const userSignOutSuccess = () => {
  return {
    type: USER_SIGNOUT_SUCCESS,
  };
};

export const userProfileFetch = () => {
  return {
    type: USER_PROFILE_FETCH,
  };
};

export const userProfileFetchSuccess = payload => {
  return {
    type: USER_PROFILE_FETCH_SUCCESS,
    payload: payload,
  };
};

export const userForgotPassword = payload => {
  return {
    type: USER_FORGOT_PASSWORD,
    payload: payload,
  };
};

export const userForgotPasswordSuccess = payload => {
  return {
    type: USER_FORGOT_PASSWORD_SUCCESS,
    payload: payload,
  };
};

export const userResetPassword = payload => {
  return {
    type: USER_RESET_PASSWORD,
    payload: payload,
  };
};

export const userResetPasswordSuccess = payload => {
  return {
    type: USER_RESET_PASSWORD_SUCCESS,
    payload: payload,
  };
};

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
