import React, { useState } from 'react';
import {
  TextField,
  InputLabel,
  OutlinedInput,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Select,
  Checkbox,
  Switch,
  Radio,
  RadioGroup,
  Divider,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import IntlMessages from '../../@jumbo/utils/IntlMessages';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

export default injectIntl(({ input, type, meta, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { error, touched } = meta ? meta : {};
  const TextFieldValid = ['text', 'number', 'email', 'textarea'];
  const PasswordFieldValid = ['password'];
  const HiddenFieldValid = ['hidden'];
  const CheckBoxFieldValid = ['checkbox'];
  const SwitchFieldValid = ['switch'];
  const SelectFieldValid = ['dropdown'];
  const AutoCompleteFieldValid = ['autocomplete'];
  const RadioGroupFieldValid = ['radiogroup'];
  const DateFieldValid = ['date'];
  const HeaderFieldValid = ['header'];
  const HrFieldValid = ['hr'];

  if (AutoCompleteFieldValid.includes(type)) {
    if (rest.default) {
      if (!_.isEmpty(rest.options)) {
        rest.options.unshift({
          display: <IntlMessages id={rest.default && rest.default.display ? rest.default.display : 'form.select.default'} />,
          value: rest.default.value,
          intl: false,
        });
      }
    }
  }

  const getSelectedOption = () => {
    let current = _.find(rest.options, function(o) {
      return o.value == input.value;
    });
    if (current != undefined) {
      const { id, values } = current.display.props;
      return {
        title: `${rest.intl.formatMessage({ id }, values)}`,
        value: current.value,
      };
    } else {
      if (rest.default) {
        return {
          title: `${rest.intl.formatMessage({ id: rest.default.display })}`,
          value: rest.default.value,
        };
      } else {
        return undefined;
      }
    }
  };

  return (
    <React.Fragment>
      {HrFieldValid.includes(type) && <Divider />}
      {HeaderFieldValid.includes(type) && <Typography {...rest}>{rest.label}</Typography>}
      {HiddenFieldValid.includes(type) && <TextField type={type} {...input} />}

      {TextFieldValid.includes(type) && (
        <TextField
          variant="outlined"
          type={type}
          error={touched && error && true}
          helperText={touched && error}
          fullWidth
          InputLabelProps={{ shrink: true }}
          {...input}
          {...rest}
        />
      )}

      {PasswordFieldValid.includes(type) && (
        <TextField
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          error={touched && error && true}
          helperText={touched && error}
          fullWidth
          {...input}
          {...rest}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}

      {DateFieldValid.includes(type) && (
        <FormControl fullWidth className={rest.className}>
          <KeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            required={rest.required}
            label={rest.label}
            disabled={rest.disabled}
            error={touched && error && true}
            helperText={touched && error}
            onChange={input.onChange}
            value={input.value ? input.value : null}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      )}

      {CheckBoxFieldValid.includes(type) && (
        <FormControl className={rest.className}>
          <FormControlLabel
            control={<Checkbox checked={input.value ? input.value : false} onChange={input.onChange} />}
            label={rest.label}
          />
        </FormControl>
      )}

      {SwitchFieldValid.includes(type) && (
        <FormControl className={rest.className}>
          <FormControlLabel
            control={<Switch checked={input.value ? input.value : false} onChange={input.onChange} color="primary" />}
            label={rest.label}
          />
        </FormControl>
      )}

      {SelectFieldValid.includes(type) && (
        <FormControl fullWidth variant="outlined" className={rest.className} disabled={rest.disabled}>
          <InputLabel error={touched && error && true} shrink={true}>
            {rest.label} {rest.required && '*'}
          </InputLabel>
          <Select
            error={touched && error && true}
            input={<OutlinedInput notched label={rest.label} id={input.name} />}
            {...input}>
            <MenuItem value={rest.default && rest.default.value ? rest.default.value : ''}>
              <IntlMessages id={rest.default && rest.default.display ? rest.default.display : 'form.select.default'} />
            </MenuItem>
            {rest.options &&
              rest.options.map(({ display, value, intl = true }) => {
                return (
                  <MenuItem key={input.name + '_' + value} value={value}>
                    {intl ? <IntlMessages id={display} /> : display}
                  </MenuItem>
                );
              })}
          </Select>
          {touched && error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      )}

      {AutoCompleteFieldValid.includes(type) && (
        <FormControl fullWidth variant="outlined" className={rest.className}>
          <Autocomplete
            value={getSelectedOption() || ''}
            options={
              rest.options &&
              rest.options.map(
                ({
                  display: {
                    props: { id, values },
                  },
                  value,
                  intl = true,
                }) => {
                  return {
                    title: `${rest.intl.formatMessage({ id }, values)}`,
                    value,
                  };
                },
              )
            }
            onChange={(event, value) => value && input.onChange(value.value)}
            getOptionLabel={option => option.title || ''}
            getOptionSelected={(optons, value) => optons.value === value.value}
            noOptionsText={rest.intl.formatMessage({
              id: 'errors.data.notfound',
            })}
            renderInput={params => (
              <TextField
                variant="outlined"
                {...params}
                type={type}
                error={touched && error && true}
                helperText={touched && error}
                label={rest.label}
              />
            )}
          />
        </FormControl>
      )}

      {RadioGroupFieldValid.includes(type) && (
        <FormControl className={rest.className}>
          <FormLabel>
            {rest.label} {rest.required && '*'}
          </FormLabel>
          <RadioGroup row={rest.row} value={input.value} onChange={input.onChange}>
            {rest.options &&
              rest.options.map(({ display, value, intl = true }) => {
                return (
                  <FormControlLabel
                    key={input.name + '_' + value}
                    value={value}
                    control={<Radio />}
                    label={intl ? <IntlMessages id={display} /> : display}
                  />
                );
              })}
          </RadioGroup>
          {touched && error && (
            <FormHelperText className="mt-0" error>
              {error}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </React.Fragment>
  );
});
