import { USERS_FETCH_SUCCESS, USERS_FETCH_RESET, USER_FETCH_SUCCESS, USER_FETCH_RESET } from '../actions/types';

const INIT_STATE = {
  users: [],
  user: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USERS_FETCH_SUCCESS: {
      return {
        ...state,
        user: {},
        users: action.payload,
      };
    }
    case USER_FETCH_SUCCESS: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case USERS_FETCH_RESET: {
      return {
        ...state,
        users: [],
      };
    }
    case USER_FETCH_RESET: {
      return {
        ...state,
        user: {},
      };
    }
    default:
      return state;
  }
};
