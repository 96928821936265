import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { showLoader, userSignIn } from '../../../redux/actions';
import { renderFields, validateField } from '../../../util/formUtil';
import { signInFormFields } from '../formFields';
import { NavLink } from 'react-router-dom';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { DIRECT_CONTACT_EMAIL } from 'constants/Systems';

class SignInForm extends Component {
  render() {
    const { handleSubmit, formValues } = this.props;

    return (
      <form
        onSubmit={handleSubmit(() => {
          this.props.showLoader();
          this.props.userSignIn(formValues);
        })}
        noValidate>
        {renderFields(signInFormFields)}
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={3}>
          <Button type="submit" variant="contained" color="primary">
            <IntlMessages id="signin" />
          </Button>
          <NavLink to={`/forgotpassword`}>
            <Box display={'flex'} alignItems={'center'}>
              <Lock fontSize="small" style={{ marginRight: 5 }} /> <IntlMessages id="forgotpassword" />
            </Box>
          </NavLink>
        </Box>
        <Box mt={5} style={{ fontSize: 13 }}>
          หากมีปัญหาการใช้งาน กรุณาติดต่อ <a href={`mailto:${DIRECT_CONTACT_EMAIL}`}>direct contact</a>
        </Box>
      </form>
    );
  }
}

function validate(values) {
  const errors = {};
  signInFormFields.forEach(({ name, required, type }) => {
    if (!values[name] && required) {
      errors[name] = <IntlMessages id="errors.field.required" />;
      return;
    }
    if (type === 'email' && !validateField(type, values[name])) {
      errors[name] = <IntlMessages id="errors.field.email" />;
      return;
    }
  });
  return errors;
}

const mapStateToProps = ({ form }) => {
  const formValues = form.SignInForm ? form.SignInForm.values : null;
  return { formValues };
};

SignInForm = reduxForm({
  validate,
  initialValues: {},
  form: 'SignInForm',
})(SignInForm);

export default withRouter(connect(mapStateToProps, { userSignIn, showLoader })(SignInForm));
