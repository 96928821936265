import React from 'react';
import { CreditCard, Person, DirectionsCar, Home, LocalAtm } from '@material-ui/icons';

import icon_01 from 'assets/images/report/icons/icon_01.png';
import icon_02 from 'assets/images/report/icons/icon_02.png';
import icon_03 from 'assets/images/report/icons/icon_03.png';
import icon_04 from 'assets/images/report/icons/icon_04.png';

export const getIcon = title => {
  switch (title) {
    case 'เงินฝาก':
      return <img src={icon_01} height={25} />;
    case 'บัตรเครดิต':
      return <CreditCard />;
    case 'บัตรเดบิต HVC':
      return <LocalAtm />;
    case 'ประกันชีวิต':
      return <img src={icon_02} height={25} />;
    case 'Unit Linked':
      return <img src={icon_03} height={25} />;
    case 'กองทุนรวม':
      return <img src={icon_04} height={25} />;
    case 'KTC พี่เบิ้ม':
      return <DirectionsCar />;
    case 'สินเชื่อเพื่อที่อยู่อาศัย':
      return <Home />;
    case 'สินเชื่อบุคคล':
      return <Person />;
    default:
      return <CreditCard />;
  }
};
