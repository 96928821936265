import React from 'react';
import { useLocation } from 'react-router-dom';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CmtImage from '../../../../@coremat/CmtImage';
import IntlMessages from '../../../utils/IntlMessages';
import ContentLoader from '../../ContentLoader';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import ResetPasswordForm from 'components/form/authen/ResetPasswordForm';
import { Box } from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ResetPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const classes = useStyles({ variant });
  const location = useLocation();
  let email = '';
  if (!_.isEmpty(location.search)) {
    let params = new URLSearchParams(location.search);
    email = params.get('email');
  }

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}

      <Box className={classes.authContent}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="resetpassword.title" />
        </Typography>
        <Typography component="div" variant="h5" style={{ marginTop: 20, marginBottom: 20 }}>
          <IntlMessages id="resetpassword.title.hint" /> {email}
        </Typography>
        <ResetPasswordForm />
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default ResetPassword;
