import React from 'react';
import IntlMessages from '../../../utils/IntlMessages';
import { ArrowForward } from '@material-ui/icons';

const dashboard = {
  name: <IntlMessages id={'dashboard'} values={{ title: '' }} />,
  type: 'item',
  link: '/app/dashboard/market-conduct',
};

const videoTutorial = {
  name: <IntlMessages id={'video-tutorial'} />,
  type: 'item',
  link: '/app/video-tutorial',
};

const executiveSummary = {
  name: <IntlMessages id={'executive-summary'} />,
  type: 'item',
  link: '/app/executive-summary',
};

const download = {
  name: <IntlMessages id={'download'} />,
  type: 'item',
  link: '/app/download',
};

const fwProgress = {
  name: <IntlMessages id={'fwprogress'} />,
  type: 'item',
  link: '/app/fwprogress',
};

const settingMenus = {
  name: <IntlMessages id={'setting'} />,
  type: 'collapse',
  children: [
    {
      name: <IntlMessages id={'user'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/app/user',
    },
    {
      name: <IntlMessages id={'branch'} />,
      icon: <ArrowForward />,
      type: 'item',
      link: '/app/branch',
    },
  ],
};

export const sidebarNavs = authUser => {
  return [
    {
      name: <IntlMessages id={'main'} />,
      type: 'section',
      children: generateMenus(authUser),
    },
  ];
};

export const horizontalDefaultNavs = authUser => {
  return [
    {
      name: <IntlMessages id={'sidebar.main'} />,
      type: 'collapse',
      children: generateMenus(authUser),
    },
  ];
};

export const minimalHorizontalMenus = authUser => {
  return [
    {
      name: <IntlMessages id={'sidebar.main'} />,
      type: 'collapse',
      children: generateMenus(authUser),
    },
  ];
};

export const generateMenus = authUser => {
  let menus = [];
  if (authUser && authUser.type) {
    if (authUser.type == 'level_1_1' || authUser.type == 'level_2') {
      menus = [executiveSummary, dashboard, fwProgress, videoTutorial];
    } else {
      menus = [executiveSummary, dashboard, fwProgress, download, videoTutorial];
    }
  }
  return menus;
};
