import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { destroy, reset } from 'redux-form';
import {
  USER_SIGNIN,
  USER_SIGNOUT,
  USER_PROFILE_FETCH,
  USER_FORGOT_PASSWORD,
  USER_RESET_PASSWORD,
} from '../redux/actions/types.js';
import {
  userSignInSuccess,
  userSignOutSuccess,
  userProfileFetchSuccess,
  userForgotPasswordSuccess,
  userResetPasswordSuccess,
} from '../redux/actions/AuthActions';
import { showErrorMessage, hideLoader, showSuccessMessage } from '../redux/actions/NotifyActions';
import { authorizationHeader, getErrorMessage } from '../util/apiUtil';
import axios from 'axios';
import {
  reportDownloadDetailFetchReset,
  reportDownloadFetchReset,
  reportExecutiveSummaryFetchReset,
  reportFwProgressFetchReset,
  reportOverallFetchReset,
} from 'redux/actions/ReportActions.js';

// API
const signInWithEmailPasswordRequest = async (email, password) =>
  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/user`, {
      email,
      password,
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });

const userProfileFetchRequest = async () =>
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/auth/user/profile`, {
      headers: authorizationHeader('bearer'),
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });

const forgotPasswordRequest = async email =>
  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/user/forgotpassword`, {
      email,
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });

const resetPasswordRequest = async (password, activate_key) =>
  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/user/resetpassword`, {
      password,
      activate_key,
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });

const signOutRequest = async () => {};

// WORKER
function* signInUserWithEmailPassword({ payload: { email, password } }) {
  try {
    const data = yield call(signInWithEmailPasswordRequest, email, password);
    if (data.success) {
      const { token, token_type } = data.success;
      localStorage.setItem('user', JSON.stringify({ token, token_type }));
      yield put(userSignInSuccess(data.success.user));
      yield put(hideLoader());
    }
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  }
}

function* userForgotPassword({ payload: { email } }) {
  try {
    const data = yield call(forgotPasswordRequest, email);
    if (data.success) {
      yield put(userForgotPasswordSuccess({}));
      yield put(hideLoader());
      yield put(showSuccessMessage(data.success.message));
    }
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  }
}

function* userResetPassword({ payload: { password, activate_key } }) {
  try {
    const data = yield call(resetPasswordRequest, password, activate_key);
    if (data.success) {
      yield put(userResetPasswordSuccess({}));
      yield put(hideLoader());
      yield put(showSuccessMessage(data.success.message));
    }
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  }
}

function* userProfileFetch() {
  try {
    const data = yield call(userProfileFetchRequest);
    yield put(userProfileFetchSuccess(data));
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  }
}

function* signOut() {
  try {
    const data = yield call(signOutRequest);
    localStorage.removeItem('user');
    yield put(userSignOutSuccess(data));
    yield put(reportOverallFetchReset());
    yield put(reportExecutiveSummaryFetchReset());
    yield put(reportDownloadFetchReset());
    yield put(reportDownloadDetailFetchReset());
    yield put(reportFwProgressFetchReset());
    yield put(reset('FilterForm'));
    yield put(reset('FilterBarForm'));
    yield put(reset('ReportDownloadForm'));
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  }
}

export function* userSignInWatcher() {
  yield takeEvery(USER_SIGNIN, signInUserWithEmailPassword);
}

export function* userProfileFetchWatcher() {
  yield takeEvery(USER_PROFILE_FETCH, userProfileFetch);
}

export function* userSignOutWatcher() {
  yield takeEvery(USER_SIGNOUT, signOut);
}

export function* userForgotPasswordWatcher() {
  yield takeEvery(USER_FORGOT_PASSWORD, userForgotPassword);
}

export function* userResetPasswordWatcher() {
  yield takeEvery(USER_RESET_PASSWORD, userResetPassword);
}

export default function* rootSaga() {
  yield all([
    fork(userSignInWatcher),
    fork(userSignOutWatcher),
    fork(userProfileFetchWatcher),
    fork(userForgotPasswordWatcher),
    fork(userResetPasswordWatcher),
  ]);
}
