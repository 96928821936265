import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Box, IconButton, makeStyles, Tooltip } from '@material-ui/core';

import { OndemandVideo } from '@material-ui/icons';

import { alpha } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  iconRoot: {
    position: 'relative',
    color: alpha(theme.palette.common.white, 0.9),
    '&:hover, &.active': {
      color: theme.palette.common.white,
    },
  },
  paper: {
    width: 375,
  },
}));

const HeaderMediaFile = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Box pr={2}>
      <Tooltip title={`${intl.formatMessage({ id: 'download.media.file' })}`}>
        <IconButton onClick={() => {}} className={clsx(classes.iconRoot, 'Cmt-appIcon', {})}>
          <OndemandVideo />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default HeaderMediaFile;
