import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  BRANCH_FETCH,
  BRANCHS_FETCH,
  BRANCH_CREATE,
  BRANCH_UPDATE,
  BRANCH_DELETE,
  BRANCHS_FILTER_FETCH,
} from '../redux/actions/types';
import { branchFetchSuccess, branchsFetchSuccess } from '../redux/actions/BranchActions';
import { branchsFilterFetchSuccess } from 'redux/actions/FilterActions';
import { showErrorMessage, showSuccessMessage, hideLoader } from '../redux/actions/NotifyActions';
import { authorizationHeader, getErrorMessage } from '../util/apiUtil';
import { intl } from '../i18n/IntlGlobalProvider';
import { reset } from 'redux-form';
import axios from 'axios';
import _ from 'lodash';

// API
const branchFetchRequest = async id =>
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/branchs/${id}`, {
      headers: authorizationHeader('bearer'),
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });

const branchsFetchRequest = async payload => {
  let url = `${process.env.REACT_APP_API_URL}/api/branchs`;
  let params = { ...payload };
  params = _.omitBy(params, _.isEmpty);

  return await axios
    .get(url, {
      headers: authorizationHeader('bearer'),
      params: params,
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

const branchCreateRequest = async values =>
  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/branchs`, values, {
      headers: authorizationHeader('bearer'),
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });

const branchUpdateRequest = async values =>
  await axios
    .put(`${process.env.REACT_APP_API_URL}/api/branchs/${values.branch_id}`, values, {
      headers: authorizationHeader('bearer'),
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });

const branchDeleteRequest = async id =>
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/api/branchs/${id}`, {
      headers: authorizationHeader('bearer'),
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });

// WORKER
function* branchFetch({ payload }) {
  try {
    const data = yield call(branchFetchRequest, payload);
    yield put(branchFetchSuccess(data));
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  }
}

function* branchsFetch({ payload }) {
  try {
    const data = yield call(branchsFetchRequest, payload);
    yield put(branchsFetchSuccess(data));
    yield put(hideLoader());
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  }
}

function* branchsFilterFetch({ payload }) {
  try {
    const data = yield call(branchsFetchRequest, payload);
    yield put(branchsFilterFetchSuccess(data));
    yield put(hideLoader());
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  }
}

function* branchCreate({ payload }) {
  try {
    const data = yield call(branchCreateRequest, payload);
    yield put(showSuccessMessage(intl.formatMessage({ id: 'success.updated' })));
    yield put(reset('BranchForm'));
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  }
}

function* branchUpdate({ payload }) {
  try {
    const data = yield call(branchUpdateRequest, payload);
    if (data.success) {
      yield put(branchFetchSuccess(data.success.result));
      yield put(showSuccessMessage(intl.formatMessage({ id: 'success.updated' })));
    }
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  }
}

function* branchDelete({ payload: id }) {
  try {
    const data = yield call(branchDeleteRequest, id);
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  }
}

// WATCHER
function* branchFetchWatcher() {
  yield takeEvery(BRANCH_FETCH, branchFetch);
}

function* branchsFetchWatcher() {
  yield takeEvery(BRANCHS_FETCH, branchsFetch);
}

function* branchCreateWatcher() {
  yield takeEvery(BRANCH_CREATE, branchCreate);
}

function* branchUpdateWatcher() {
  yield takeEvery(BRANCH_UPDATE, branchUpdate);
}

function* branchDeleteWatcher() {
  yield takeEvery(BRANCH_DELETE, branchDelete);
}

function* branchsFilterFetchWatcher() {
  yield takeEvery(BRANCHS_FILTER_FETCH, branchsFilterFetch);
}

export default function* rootSaga() {
  yield all([
    fork(branchFetchWatcher),
    fork(branchsFetchWatcher),
    fork(branchCreateWatcher),
    fork(branchUpdateWatcher),
    fork(branchDeleteWatcher),
    fork(branchsFilterFetchWatcher),
  ]);
}
