import React from 'react';
import { Field } from 'redux-form';
import FormField from '../components/form/FormField';
import { Grid } from '@material-ui/core';
import IntlMessages from '../@jumbo/utils/IntlMessages';

export const renderFields = formFields => {
  return (
    <Grid container spacing={4} justifyContent="flex-end">
      {formFields.map(({ name, label, translate = true, grid, ...rest }, i) => {
        return (
          <Grid {...grid} item key={i} style={{ display: rest.type == 'hidden' ? 'none' : 'block' }}>
            {name && (
              <Field label={translate ? <IntlMessages id={label} /> : label} name={name} component={FormField} {...rest} />
            )}
            {!name && <FormField label={translate ? <IntlMessages id={label} /> : label} {...rest} />}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const insertOptions = (formFields, name, arr, value, display, intl = false) => {
  let options = [];
  if (arr && arr.length > 0) {
    arr.map(element => {
      options.push({ display: element[display], value: element[value], intl });
    });
  }
  formFields.forEach(element => {
    if (element.name === name) {
      element.options = options;
    }
  });
  return formFields;
};

export const selectOptions = (formFields, name, values) => {
  formFields.forEach(element => {
    if (element.name === name) {
      element.options.forEach((option, i) => {
        if (option.value != 'all' && !values.includes(parseInt(option.value))) {
          delete element.options[i];
        }
      });
    }
  });
  return formFields;
};

export const deleteOptions = (formFields, name, values, displays = []) => {
  formFields.forEach(element => {
    if (element.name === name) {
      element.options.forEach((option, i) => {
        if (option.value != 'all' && values.includes(parseInt(option.value))) {
          delete element.options[i];
        }
        if (displays.includes(option.display)) {
          delete element.options[i];
        }
      });
    }
  });
  return formFields;
};

export const updateProperties = (formFields, name, properties) => {
  formFields.forEach(element => {
    if (element.name === name) {
      Object.keys(properties).forEach(property => {
        element[property] = properties[property];
      });
    }
  });
  return formFields;
};

export const validateField = (method, value) => {
  switch (method) {
    case 'email':
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
      );
    case 'url':
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        value,
      );
  }
};
