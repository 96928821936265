import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  REPORT_OVERALL_FETCH,
  REPORT_FWPROGRESS_FETCH,
  REPORT_DOWNLOAD_FETCH,
  REPORT_DOWNLOAD_V1_FETCH,
  REPORT_DOWNLOAD_DETAIL_FETCH,
  REPORT_EXECUTIVE_SUMMARY_FETCH,
  REPORT_VIDEO_TUTORIAL_FETCH,
} from '../redux/actions/types';
import {
  reportOverallFetchSuccess,
  reportFwProgressFetchSuccess,
  reportDownloadV1FetchSuccess,
  reportDownloadFetchSuccess,
  reportDownloadDetailFetchSuccess,
  reportExecutiveSummaryFetchSuccess,
  reportVideoTutorialFetchSuccess,
} from '../redux/actions/ReportActions';
import { showErrorMessage, hideLoader } from '../redux/actions/NotifyActions';
import { getErrorMessage } from '../util/apiUtil';
import axios from 'axios';
import _ from 'lodash';

// API
export const reportOverallFetchRequest = async payload => {
  let url = `${process.env.REACT_APP_API_SURVEY_URL}_api/overall/`;
  let params = { ...payload };
  let auth = {
    username: process.env.REACT_APP_API_SURVEY_USER,
    password: process.env.REACT_APP_API_SURVEY_PASSWORD,
  };
  params = _.omitBy(params, _.isEmpty);

  return await axios
    .post(url, params, { auth })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export const reportFwProgressFetchRequest = async payload => {
  let url = `${process.env.REACT_APP_API_SURVEY_URL}_api/fwprogress/`;
  let params = { ...payload };
  let auth = {
    username: process.env.REACT_APP_API_SURVEY_USER,
    password: process.env.REACT_APP_API_SURVEY_PASSWORD,
  };
  params = _.omitBy(params, _.isEmpty);

  return await axios
    .post(url, params, { auth })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export const reportDownloadV1FetchRequest = async payload => {
  let url = `${process.env.REACT_APP_API_SURVEY_URL}_api/download/`;
  let params = { ...payload };
  let auth = {
    username: process.env.REACT_APP_API_SURVEY_USER,
    password: process.env.REACT_APP_API_SURVEY_PASSWORD,
  };
  params = _.omitBy(params, _.isEmpty);

  return await axios
    .post(url, params, { auth })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export const reportDownloadFetchRequest = async payload => {
  let url = `${process.env.REACT_APP_API_SURVEY_URL}_api/downloadtable/`;
  let params = { ...payload };
  let auth = {
    username: process.env.REACT_APP_API_SURVEY_USER,
    password: process.env.REACT_APP_API_SURVEY_PASSWORD,
  };
  params = _.omitBy(params, _.isEmpty);

  return await axios
    .post(url, params, { auth })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export const reportDownloadDetailFetchRequest = async payload => {
  let url = `${process.env.REACT_APP_API_SURVEY_URL}_api/downloaddialog/`;
  let params = { ...payload };
  let auth = {
    username: process.env.REACT_APP_API_SURVEY_USER,
    password: process.env.REACT_APP_API_SURVEY_PASSWORD,
  };
  params = _.omitBy(params, _.isEmpty);

  return await axios
    .post(url, params, { auth })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export const reportExecutiveSummaryFetchRequest = async payload => {
  let url = `${process.env.REACT_APP_API_SURVEY_URL}_api/executive/`;
  let params = { ...payload };
  let auth = {
    username: process.env.REACT_APP_API_SURVEY_USER,
    password: process.env.REACT_APP_API_SURVEY_PASSWORD,
  };
  params = _.omitBy(params, _.isEmpty);

  return await axios
    .post(url, params, { auth })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export const reportVideoTutorialFetchRequest = async payload => {
  let url = `${process.env.REACT_APP_API_SURVEY_URL}_api/newvideo/`;
  let params = { ...payload };
  let auth = {
    username: process.env.REACT_APP_API_SURVEY_USER,
    password: process.env.REACT_APP_API_SURVEY_PASSWORD,
  };
  params = _.omitBy(params, _.isEmpty);

  return await axios
    .post(url, params, { auth })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

// WORKER
function* reportOverallFetch({ payload }) {
  try {
    console.log(payload);
    const data = yield call(reportOverallFetchRequest, payload);
    if (data.success) {
      yield put(reportOverallFetchSuccess({ ...data, filter: payload }));
    }
    if (!data.success) {
      yield put(reportOverallFetchSuccess(undefined));
    }
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  } finally {
    yield put(hideLoader());
  }
}

function* reportFwProgressFetch({ payload }) {
  try {
    console.log(payload);
    const data = yield call(reportFwProgressFetchRequest, payload);
    if (data.success) {
      yield put(reportFwProgressFetchSuccess({ ...data, filter: payload }));
    }
    if (!data.success) {
      yield put(reportFwProgressFetchSuccess(undefined));
    }
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  } finally {
    yield put(hideLoader());
  }
}

function* reportDownloadV1Fetch({ payload }) {
  try {
    console.log(payload);
    const data = yield call(reportDownloadV1FetchRequest, payload);
    if (data.success) {
      yield put(reportDownloadV1FetchSuccess(data));
    }
    if (!data.success) {
      yield put(reportDownloadV1FetchSuccess(undefined));
    }
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  } finally {
    yield put(hideLoader());
  }
}

function* reportDownloadFetch({ payload }) {
  try {
    console.log(payload);
    const data = yield call(reportDownloadFetchRequest, payload);
    if (data.success) {
      yield put(reportDownloadFetchSuccess({ ...data, filter: payload }));
    }
    if (!data.success) {
      yield put(reportDownloadFetchSuccess(undefined));
    }
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  } finally {
    yield put(hideLoader());
  }
}

function* reportDownloadDetailFetch({ payload }) {
  try {
    console.log(payload);
    const data = yield call(reportDownloadDetailFetchRequest, payload);
    if (data.success) {
      yield put(reportDownloadDetailFetchSuccess({ ...data, filter: payload }));
    }
    if (!data.success) {
      yield put(reportDownloadDetailFetchSuccess(undefined));
    }
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  } finally {
    yield put(hideLoader());
  }
}

function* reportExecutiveSummaryFetch({ payload }) {
  try {
    console.log(payload);
    const data = yield call(reportExecutiveSummaryFetchRequest, payload);
    if (data.success) {
      yield put(reportExecutiveSummaryFetchSuccess({ ...data, filter: payload }));
    }
    if (!data.success) {
      yield put(reportExecutiveSummaryFetchSuccess(undefined));
    }
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  } finally {
    yield put(hideLoader());
  }
}

function* reportVideoTutorialFetch({ payload }) {
  try {
    console.log(payload);
    const data = yield call(reportVideoTutorialFetchRequest, payload);
    if (data.success) {
      yield put(reportVideoTutorialFetchSuccess({ ...data, filter: payload }));
    }
    if (!data.success) {
      yield put(reportVideoTutorialFetchSuccess(undefined));
    }
  } catch (error) {
    yield put(showErrorMessage(getErrorMessage(error)));
  } finally {
    yield put(hideLoader());
  }
}

// WATCHER
function* reportOverallFetchWatcher() {
  yield takeEvery(REPORT_OVERALL_FETCH, reportOverallFetch);
}

function* reportFwProgressFetchWatcher() {
  yield takeEvery(REPORT_FWPROGRESS_FETCH, reportFwProgressFetch);
}

function* reportDownloadFetchWatcher() {
  yield takeEvery(REPORT_DOWNLOAD_FETCH, reportDownloadFetch);
}

function* reportDownloadV1FetchWatcher() {
  yield takeEvery(REPORT_DOWNLOAD_V1_FETCH, reportDownloadV1Fetch);
}

function* reportDownloadDetailFetchWatcher() {
  yield takeEvery(REPORT_DOWNLOAD_DETAIL_FETCH, reportDownloadDetailFetch);
}

function* reportExecutiveSummaryFetchWatcher() {
  yield takeEvery(REPORT_EXECUTIVE_SUMMARY_FETCH, reportExecutiveSummaryFetch);
}

function* reportVideoTutorialFetchWatcher() {
  yield takeEvery(REPORT_VIDEO_TUTORIAL_FETCH, reportVideoTutorialFetch);
}

export default function* rootSaga() {
  yield all([
    fork(reportOverallFetchWatcher),
    fork(reportFwProgressFetchWatcher),
    fork(reportDownloadFetchWatcher),
    fork(reportDownloadV1FetchWatcher),
    fork(reportDownloadDetailFetchWatcher),
    fork(reportExecutiveSummaryFetchWatcher),
    fork(reportVideoTutorialFetchWatcher),
  ]);
}
