import {
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_ERROR_MESSAGE,
  SHOW_WARNING_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  SHOW_INFO_MESSAGE,
} from '../actions/types';

const INIT_STATE = {
  loader: false,
  showMessage: false,
  errorMessage: '',
  successMessage: '',
  infoMessage: '',
  warningMessage: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_ERROR_MESSAGE: {
      return {
        loader: false,
        showMessage: true,
        errorMessage: action.payload,
        successMessage: '',
        infoMessage: '',
        warningMessage: '',
      };
    }
    case SHOW_WARNING_MESSAGE: {
      return {
        loader: false,
        showMessage: true,
        errorMessage: '',
        successMessage: '',
        infoMessage: '',
        warningMessage: action.payload,
      };
    }
    case SHOW_SUCCESS_MESSAGE: {
      return {
        loader: false,
        showMessage: true,
        errorMessage: '',
        successMessage: action.payload,
        infoMessage: '',
        warningMessage: '',
      };
    }
    case SHOW_INFO_MESSAGE: {
      return {
        loader: false,
        showMessage: true,
        errorMessage: '',
        successMessage: '',
        infoMessage: action.payload,
        warningMessage: '',
      };
    }
    case HIDE_MESSAGE: {
      return {
        loader: false,
        showMessage: false,
        errorMessage: '',
        successMessage: '',
        infoMessage: '',
        warningMessage: '',
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};
