import { useIntl } from 'react-intl';

var INTL;
const IntlGlobalProvider = ({ children }) => {
  INTL = useIntl();
  return children;
};

var instance;
class IntlTranslator {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  formatMessage(message, values) {
    return INTL.formatMessage(message, values);
  }
}

export const intl = new IntlTranslator();
export default IntlGlobalProvider;
