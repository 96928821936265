import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { showLoader, hideLoader, showWarningMessage } from '../../../redux/actions/NotifyActions';
import {
  reportOverallFetch,
  reportFwProgressFetch,
  reportExecutiveSummaryFetch,
} from '../../..//redux/actions/ReportActions';
import { hideFilter } from 'redux/actions';
import { renderFields, insertOptions, selectOptions, updateProperties } from '../../../util/formUtil';
import { filterFormFields } from '../formFields';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import { Grid, Box, Button } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';

class FilterForm extends Component {
  static contextType = AppContext;
  static area_offices = [];
  static area_office_regions = [];
  static location_types = [];
  static branch_codes = [];

  setDefaultFilter(prevProps) {
    const { change, formValues, authUser } = this.props;
    if (prevProps) {
      const { formValues: prevformValues } = prevProps;

      if (formValues && prevformValues) {
        if (formValues.branch.area_office_region != prevformValues.branch.area_office_region) {
          change('branch.area_office', 'all');
          change('branch.branch_code', 'all');
        } else if (formValues.branch.area_office != prevformValues.branch.area_office) {
          change('branch.branch_code', 'all');
          change('branch.location_type', 'all');
        } else if (formValues.branch.location_type != prevformValues.branch.location_type) {
          change('branch.branch_code', 'all');
        }
      }
    }
  }

  componentDidMount() {
    this.setDefaultFilter();
  }

  componentDidUpdate(prevProps) {
    this.setDefaultFilter(prevProps);
  }

  componentWillUnmount() {}

  render() {
    const { locale } = this.context;
    const { location, formValues, handleSubmit, reset, branchs, authUser } = this.props;

    let filterParams = _.omitBy(
      {
        area_office:
          _.has(formValues, 'branch.area_office') && formValues.branch.area_office != 'all'
            ? formValues.branch.area_office
            : undefined,
        area_office_region:
          _.has(formValues, 'branch.area_office_region') && formValues.branch.area_office_region != 'all'
            ? formValues.branch.area_office_region
            : undefined,
        location_type:
          _.has(formValues, 'branch.location_type') && formValues.branch.location_type != 'all'
            ? formValues.branch.location_type
            : undefined,
        branch_code:
          _.has(formValues, 'branch.branch_code') && formValues.branch.branch_code != 'all'
            ? formValues.branch.branch_code
            : undefined,
      },
      _.isUndefined,
    );

    const filterFormFields_ = _.cloneDeep(filterFormFields);

    let branchs_ = _.orderBy(branchs, ['branch_code'], ['asc']);
    // TYPE
    if (authUser && authUser.user_id) {
      if (!_.isEmpty(authUser.user_area_office_region)) {
        // area_office_regions
        const condition = authUser.user_area_office_region.map(row => row.area_office_region);
        branchs_ = _.filter(branchs_, o => condition.includes(o.area_office_region));
      }
      if (!_.isEmpty(authUser.user_area_office)) {
        // area_office
        const condition = authUser.user_area_office.map(row => row.area_office);
        branchs_ = _.filter(branchs_, o => condition.includes(o.area_office));
      }
      if (!_.isEmpty(authUser.user_product) && authUser.type == 'level_2') {
        // product
        const condition = authUser.user_product.map(row => row.product_id);
        selectOptions(filterFormFields_, 'product', condition);
      }
    }

    // AREA_OFFICE_REGION
    let area_office_regions = _.orderBy(branchs_, ['area_office_region'], ['asc']);
    area_office_regions = _.filter(area_office_regions, _.pick(filterParams, []));
    area_office_regions = _.uniqBy(area_office_regions, 'area_office_region');
    this.area_office_regions = area_office_regions.map(element => {
      let { area_office_region } = element;
      let area_office_region_ = <IntlMessages id="area_office_region.display" values={{ area_office_region }} />;
      return { id: area_office_region, area_office_region_ };
    });

    // AREA_OFFICE
    let area_offices = _.orderBy(branchs_, ['area_office'], ['asc']);
    area_offices = _.filter(area_offices, _.pick(filterParams, ['area_office_region']));
    area_offices = _.uniqBy(area_offices, 'area_office');
    this.area_offices = area_offices.map(element => {
      let { area_office } = element;
      let area_office_ = <IntlMessages id="area_office.display" values={{ area_office }} />;
      return { id: area_office, area_office_ };
    });

    // LOCATION_TYPE
    let location_types = _.orderBy(branchs_, ['location_type'], ['asc']);
    location_types = _.filter(location_types, _.pick(filterParams, ['area_office_region', 'area_office']));
    location_types = _.uniqBy(location_types, 'location_type');
    this.location_types = location_types.map(element => {
      let { location_type } = element;
      return { id: location_type, location_type };
    });

    // BRANCH_NAME
    let branch_codes = _.orderBy(branchs_, ['branch_code'], ['asc']);
    branch_codes = _.filter(branch_codes, _.pick(filterParams, ['area_office_region', 'area_office', 'location_type']));
    this.branch_codes = branch_codes.map(element => {
      let { branch_code, branch_name_th } = element;
      let branch_name_ = <IntlMessages id="branch.display" values={{ branch_code, branch_name: branch_name_th }} />;
      return { id: `${branch_code}`, branch_name_ };
    });

    insertOptions(filterFormFields_, 'branch.area_office_region', this.area_office_regions, 'id', 'area_office_region_');
    insertOptions(filterFormFields_, 'branch.area_office', this.area_offices, 'id', 'area_office_');
    insertOptions(filterFormFields_, 'branch.location_type', this.location_types, 'id', 'location_type');
    insertOptions(filterFormFields_, 'branch.branch_code', this.branch_codes, 'id', 'branch_name_');

    // REMOVE
    if (formValues && formValues.branch.area_office_region == 'all') {
      updateProperties(filterFormFields_, 'branch.area_office', {
        type: 'hidden',
      });
      updateProperties(filterFormFields_, 'branch.branch_code', {
        type: 'hidden',
      });
    } else {
      updateProperties(filterFormFields_, 'branch.area_office', {
        type: 'autocomplete',
      });
      updateProperties(filterFormFields_, 'branch.branch_code', {
        type: 'hidden',
      });
    }

    return (
      <form>
        {renderFields(filterFormFields_)}
        <Box mt={4}>
          <Grid container spacing={3}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit(() => {
                  this.props.showLoader();
                  // RESET
                  let { ...rest } = formValues;
                  let filter = {
                    y: rest.year,
                    q: rest.batch,
                    w: rest.week,
                    location: rest.branch.location_type,
                    branch_id: rest.branch.branch_code,
                    area: rest.branch.area_office,
                    zone: rest.branch.area_office_region,
                    product: rest.product,
                    areas: authUser.user_area_office ? authUser.user_area_office.map(row => row.area_office) : [],
                    zones: authUser.user_area_office_region
                      ? authUser.user_area_office_region.map(row => row.area_office_region)
                      : [],
                    products: authUser.user_product ? authUser.user_product.map(row => row.product_id) : [],
                    level: authUser.type,
                  };
                  console.log(rest);
                  console.log(filter);

                  // CALL
                  if (location.pathname.indexOf('app/dashboard') != -1) {
                    if (authUser && authUser.user_id && authUser.type == 'level_2') {
                      if (filter.zone != 'all' && filter.product == 'all') {
                        this.props.showWarningMessage('กรุณาเลือกผลิตภัณฑ์');
                        return;
                      }
                    }
                    this.props.reportOverallFetch(filter);
                  } else if (location.pathname.indexOf('app/fwprogress') != -1) {
                    this.props.reportFwProgressFetch(filter);
                  } else if (location.pathname.indexOf('app/executive-summary') != -1) {
                    this.props.reportExecutiveSummaryFetch(filter);
                  } else {
                    this.props.hideLoader();
                  }
                  this.props.hideFilter();
                })}>
                <IntlMessages id="commands.filter" />
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit(() => {
                  reset('FilterForm');
                })}>
                <IntlMessages id="commands.filter.clear" />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    );
  }
}

function validate(values) {
  const errors = {};
  filterFormFields.forEach(({ name, required, type }) => {
    if (!values[name] && required) {
      errors[name] = <IntlMessages id="errors.field.required" />;
      return;
    }
  });
  return errors;
}

const mapStateToProps = ({ auth: { authUser }, form, filter: { branchs } }) => {
  const formValues = form.FilterForm ? form.FilterForm.values : null;
  let year = moment().format('YYYY');
  let batch = '1';
  let week = 'all';
  if (authUser && authUser.user_id) {
    return {
      formValues,
      branchs,
      authUser,
      initialValues: {
        year,
        batch,
        week,
        product: 'all',
        branch: {
          area_office: 'all',
          area_office_region: 'all',
          location_type: 'all',
          branch_code: 'all',
        },
      },
    };
  }
  return { formValues, branchs, authUser };
};

FilterForm = reduxForm({
  validate,
  form: 'FilterForm',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(FilterForm);

export default withRouter(
  connect(mapStateToProps, {
    showLoader,
    hideLoader,
    hideFilter,
    showWarningMessage,
    reportOverallFetch,
    reportFwProgressFetch,
    reportExecutiveSummaryFetch,
  })(FilterForm),
);
