import {
  SHOW_FILTER,
  HIDE_FILTER,
  TOGGLE_FILTER,
  BRANCHS_FILTER_FETCH,
  BRANCHS_FILTER_FETCH_SUCCESS,
  BRANCHS_FILTER_FETCH_RESET,
} from './types';

// SHOW
export const showFilter = () => {
  return {
    type: SHOW_FILTER,
  };
};

// HIDE
export const hideFilter = () => {
  return {
    type: HIDE_FILTER,
  };
};

// TOGGLE
export const toggleFilter = () => {
  return {
    type: TOGGLE_FILTER,
  };
};

export const branchsFilterFetch = payload => {
  return {
    type: BRANCHS_FILTER_FETCH,
    payload: payload,
  };
};

export const branchsFilterFetchSuccess = payload => {
  return {
    type: BRANCHS_FILTER_FETCH_SUCCESS,
    payload: payload,
  };
};

export const branchsFilterFetchReset = () => {
  return {
    type: BRANCHS_FILTER_FETCH_RESET,
  };
};
